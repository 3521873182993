import {
  Component,
  OnInit,
  Renderer2,
  HostListener,
  Inject,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef
} from "@angular/core";
import { Location } from "@angular/common";
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { NavFacade } from "./nav/nav.facade";
import { MainFacade } from "./pages/main.facade";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, AfterViewInit {
  showSide:boolean = false;
  public project$: Observable<any>|undefined;
  public selectListing$: Observable<any>|undefined;
  public recentBuy$: Observable<any>|undefined;
  public filters$: Observable<any>|undefined;
  public nightMode$: Observable<any>|undefined;
  public selectSell$: Observable<any>|undefined;
  public selectUserBuy$: Observable<any>|undefined;
  public pg$: Observable<any>|undefined;
  private destroy$ = new Subject();
  @ViewChild('music') myModal:ElementRef;
  project:string = 'godjira';
  showSwitch:boolean = false;
  showDropdown:boolean = false;
  playing:boolean = false;
  projectBG:any="";
  projectColor:any="";
  locNav:any="";
  showBoob:boolean=false;
  constructor(
    private renderer: Renderer2,
    public location: Location,
    @Inject(DOCUMENT) document,
    private router: Router,
    private navFacade: NavFacade,
    private mainFacade:MainFacade
  ) {
    document.addEventListener("click", (ele):void => {
      if(ele?.target && (ele.target as Element).className && (ele.target as Element).className.indexOf('dd') < 0 ){
        this.showDropdown = false;
      }
      
    });
    router.events.subscribe((val:any) => {
      if(val?.url?.indexOf("/market") >= 0){
        this.locNav = 'market';
      }
      else if(val?.url?.indexOf("/home") >= 0){
        this.locNav = 'dash';
      }
      else if(val?.url?.indexOf("/myselling") >= 0){
        this.locNav = 'myselling';
      }
      else if(val?.url?.indexOf("/usermarket") >= 0){
        this.locNav = 'usermarket';
      }
    });


   
    
  }


  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
  
    if (window.pageYOffset > 100) {
      var element = document.getElementById("navbar-top");
      if (element) {
        element.classList.remove("navbar-transparent");
        element.classList.add("bg-primary");
        
      }
    } else {
      var element = document.getElementById("navbar-top");
      if (element && this.router.url !== '/game') {
        element.classList.add("navbar-transparent");
        element.classList.remove("bg-primary");
      }
    }
    
  }

  ngOnInit() {
    this.onWindowScroll(event);
    this.createSubscriptions();
    
  }

  ngAfterViewInit(): void {
    //this.myModal.nativeElement.play();
  }

  pause(){
    this.myModal.nativeElement.muted = true;
    //this.myModal.nativeElement.play = false;
    this.playing = false;
  }

  play(){
    this.myModal.nativeElement.play();
    this.myModal.nativeElement.muted = false;
    //this.myModal.nativeElement.play = true;
    this.playing = true;
  }

  updateProject(projectName){
    this.showDropdown = false;
    this.navFacade.changeProject(projectName);
  }

  createSubscriptions(){
    this.pg$ = this.mainFacade.pg$;
    this.project$ = this.navFacade.project$;
    this.selectListing$ = this.mainFacade.selectListing$;
    this.selectSell$ = this.mainFacade.selectSell$;
    this.selectUserBuy$ = this.mainFacade.selectUserBuy$;
    this.recentBuy$ = this.mainFacade.recentBuy$;
    this.filters$ = this.mainFacade.filters$;
    this.nightMode$ = this.navFacade.nightMode$;
    //this.navFacade.changeProject('godjira');
    this.project$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){      
        this.projectBG = message.Background
      }

    });

    this.pg$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){      
        this.boob();
      }

    });
  }

  delay(t) {
    return new Promise(resolve => setTimeout(resolve, t))
  }

  async boob(){
    await this.delay(15000);
    //show jira
    let boobItem = document.getElementById('boob');
    boobItem.classList.remove('hidden');
    await this.delay(3000);
    boobItem.classList.add('hidden');
    await this.delay(15000);
    let boob2Item = document.getElementById('boob2');
    boob2Item.classList.remove('hidden');
    await this.delay(3000);
    boob2Item.classList.add('hidden');
    await this.delay(15000);
    let boob3Item = document.getElementById('boob3');
    boob3Item.classList.remove('hidden');
    await this.delay(3000);
    boob3Item.classList.add('hidden');
    await this.delay(15000);
    this.recursiveBoob();

  }

  recursiveBoob(){
    this.boob();
  }

  controlModal(rou){
    this.router.navigate([rou]);
  }
  
  
}
