import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crappybird',
  templateUrl: './crappybird.component.html',
  styleUrls: ['./crappybird.component.scss']
})
export class CrappybirdComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
