import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MainFacade } from '../main.facade';
import { CountdownConfig } from 'ngx-countdown';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ToastService } from 'angular-toastify';
import { takeUntil} from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
declare var $: any;
var moment = require('moment-timezone');
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  public hot$: Observable<any>|undefined;
  public topSpenders$: Observable<any>|undefined;
  public user$: Observable<any>|undefined;
  public purchase$: Observable<any>|undefined;
  public bid$: Observable<any>|undefined;
  public selectListing$: Observable<any>|undefined;
  public myLikes$: Observable<any>|undefined;
  public project$: Observable<any>|undefined;
  public projectLis$: Observable<any>|undefined;
  public nightMode$: Observable<any>|undefined;
  public nfts$: Observable<any>|undefined;
  public showNfts$: Observable<any>|undefined;
  public allSell$: Observable<any>|undefined;
  allSellSlide:any=  {
    type:'allsell',
    count:0,
    numShow:0,
    max:0,
    move:''
  };
  gameSlide:any={
    type:'games',
    count:0,
    numShow:0,
    max:0,
    move:''
  };
  listingSlide:any={
    type:'listings',
    count:0,
    numShow:0,
    max:0,
    move:''
  };

  
  selectListing:any;
  myLikes:any[];
  projLis:any[];
  private destroy$ = new Subject();
  user:any;
  quantity:number = 1;
  bid:number=0;
  id:number;
  clickId:number = 1;
  featurePos:number = 0;
  cycleStarted = false;
  cardBorder:any='';
  customOptions: OwlOptions = {
    items: 1,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 10,
    responsiveRefreshRate: 1000,
    loop:true,
    autoplay:true,
    margin:0,
    autoplaySpeed: 3000,
    smartSpeed: 3000,
    slideTransition: 'linear',
    autoplayHoverPause:true
  };

  customOptionsNfts: OwlOptions = {
    items:20,
    autoWidth:true,
    responsive:{},
    margin:0,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 900,
    responsiveRefreshRate: 200,
    loop:true,
    autoplay:false,
    slideTransition: 'linear',
    autoplayHoverPause:true
  };

  constructor(private mainfacade:MainFacade, private sanitizer: DomSanitizer, private toastService: ToastService, private router:Router) { 

    document.addEventListener("mousedown", (ele):void => {
     
      if(ele?.target && (ele.target as Element).className && (ele.target as Element).className.indexOf('listing') < 0 && (ele.target as Element).className.indexOf('buy') < 0){
        //this.selectListing = null;
      }
      
      
    });
    /*$('.owl-carousel').trigger('play.owl.autoplay',[2000]);
      setTimeout(()=>{
        $('.owl-carousel').trigger('play.owl.autoplay',[5000]);
      }, 2000);*/
  }

 

 
  ngOnInit(): void {
    if(window.screen.width <= 760){
      this.customOptions.items = 1.5;
    }
    this.createSubscriptions();
  }



  async repeatCycle(){
    await this.delay(7000);
    this.changeFeatPro();
  }

  changeFeatPro(){
    if(this.featurePos + 1 > this.projLis.length-1){
      this.featurePos = 0;
      
    }
    else{
      if(this.projLis[this.featurePos + 1] && this.projLis[this.featurePos + 1].Id === 4){
        if(this.featurePos + 2 > this.projLis.length-1){
          this.featurePos = 0;
        }
        else{
          this.featurePos += 2;
        }
      }
      else{
        this.featurePos += 1;
      }
    }

    this.repeatCycle();
    
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  safeSVGRet(svgContent:any){
    return this.sanitizer.bypassSecurityTrustHtml(svgContent);
  }

  createSubscriptions(){
    this.hot$ = this.mainfacade.hot$;
    this.topSpenders$ = this.mainfacade.topSpenders$;
    this.user$ = this.mainfacade.user$;
    this.purchase$ = this.mainfacade.purchase$;
    this.bid$ = this.mainfacade.bid$;
    this.selectListing$ = this.mainfacade.selectListing$;
    this.myLikes$ = this.mainfacade.myLikes$;
    this.project$ = this.mainfacade.project$;
    this.projectLis$ = this.mainfacade.projectLis$;
    this.nightMode$ = this.mainfacade.nightMode$;
    this.nfts$ = this.mainfacade.nfts$;
    this.showNfts$ = this.mainfacade.showNfts$;
    this.allSell$ = this.mainfacade.allSell$

    this.allSell$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        
        //let allSellSpace = document.getElementById('usersell');

        //this.allSellSlide!.numShow = Math.floor(allSellSpace.clientWidth/275);
        //this.allSellSlide!.max = message.length /this.allSellSlide.numShow
      }

    });

    this.user$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.user = message;
        //this.user.Wallet = this.user.Wallets[0];
        //this.userId = message.Id;
        //this.mainfacade.potByUser(this.userId);
      }

    });

    this.hot$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        let listingSpace = document.getElementById('listings');

        this.listingSlide!.numShow = Math.floor(listingSpace.clientWidth/318);
        this.listingSlide!.max = message.length /this.listingSlide.numShow;
        console.log(this.listingSlide)

        if(window.screen.width > 1600){
          this.customOptions.items = 5;
            this.customOptions.autoplay = true;
        }
        else if(window.screen.width > 760){
          this.customOptions.items = 4;
            this.customOptions.autoplay = true;
        }
        else {
          this.customOptions.items = 2.5;
          this.customOptions.autoplay = true;
        
        }
        
       
        //this.userId = message.Id;
        //this.mainfacade.potByUser(this.userId);
      }

    });


    this.myLikes$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.myLikes = message;
        //this.userId = message.Id;
        //this.mainfacade.potByUser(this.userId);
      }

    });

    this.project$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.id = message.Id;
        this.mainfacade.getHotListings(this.id);
        //this.userId = message.Id;
        //this.mainfacade.potByUser(this.userId);
      }

    });

    this.projectLis$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.projLis = message;
        if(!this.cycleStarted){
          this.cycleStarted = true;
          this.repeatCycle();
        }
        
      }

    });

    //this.mainfacade.getHotListings(1);
    this.mainfacade.allSell('','')
    
  }


  async startTimer(seconds:number){
    let mili = seconds * 1000;
    if(mili > 0){
      await this.delay(mili);
    }
    
  }

  delay(t) {
    return new Promise(resolve => setTimeout(resolve, t))
  }
  
  getTime(date:any){
    if(!date){
      return '0';
    }
    var d = new Date();
    var dP = new Date(date);
    var myDatetimeFormat= "YYYY-MM-DD hh:mm:ss A";
    var myDatetimeString = moment.utc(d).format(myDatetimeFormat);/// tz(myTimezone).format(myDatetimeFormat);
    var myDatetimeStringPost = moment.utc(dP).format(myDatetimeFormat);
    let duration = moment.duration(moment(myDatetimeStringPost).diff(moment(myDatetimeString)));

    if(moment(myDatetimeString).isSameOrAfter(moment(myDatetimeStringPost))){
      return 0;
    }
    let seconds = duration.asSeconds();
    
    return !seconds || seconds > 0 ? seconds  : '0';
  }

  getDays(date:any){
    if(!date){
      return '0';
    }
    var d = new Date();
    var dP = new Date(date);
    var myDatetimeFormat= "YYYY-MM-DD hh:mm:ss A";
    var myDatetimeString = moment.utc(d).format(myDatetimeFormat);/// tz(myTimezone).format(myDatetimeFormat);
    var myDatetimeStringPost = moment.utc(dP).format(myDatetimeFormat);
    let duration = moment.duration(moment(myDatetimeStringPost).diff(moment(myDatetimeString)));
   
    if(moment(myDatetimeString).isSameOrAfter(moment(myDatetimeStringPost))){
      return 0;
    }
    let seconds = duration.asSeconds();
 
    return !seconds || seconds > 0 ? Math.floor(seconds/60/60/24)  : 0;
  }


  like(listingId, type){
    this.mainfacade.likesListing(this.user.Id, listingId,type);
  }

  liked(listingId){
    return this.myLikes.find((x)=>{
      return listingId === x.Id;
    })
  }

  navToList(ele, id, pId, gtype){

   if(ele?.target && (ele.target as Element).className && (ele.target as Element).className.indexOf('like') >= 0){
    //this.selectListing = null;
    }
    else{
      this.router.navigate(['market'], { queryParams: {id: id, project:pId, gtype:gtype}});
    }
    //this.mainfacade.selectListing(id);
   
  }

  selectBuy(listing){
    console.log(listing)
    this.mainfacade.selectUserBuy(listing);
  }

  next(status, item){
    if(item==='usersell'){
      if(status=='back' && this.allSellSlide!.count >= 1){
        this.allSellSlide!.count -= 1;
        this.allSellSlide!.move='translateX(-'+(this.allSellSlide!.count*this.allSellSlide!.numShow*277) +'px)';
       } 
       else if(status=='forward' && (this.allSellSlide!.count+1) < this.allSellSlide!.max){
        this.allSellSlide!.count += 1;
        this.allSellSlide!.move='translateX(-'+(this.allSellSlide!.count*this.allSellSlide!.numShow*277) +'px)';
       } 
    }
    else if (item === 'listings'){
      if(status=='back' && this.listingSlide!.count >= 1){
        this.listingSlide!.count -= 1;
        this.listingSlide!.move='translateX(-'+(this.listingSlide!.count*this.listingSlide!.numShow*12) +'rem)';
       } 
       else if(status=='forward' && (this.listingSlide!.count+1) < this.listingSlide!.max){
        this.listingSlide!.count += 1;
        this.listingSlide!.move='translateX(-'+(this.listingSlide!.count*this.listingSlide!.numShow*12) +'rem)';
       } 
    }

    else if (item === 'games'){
      if(status=='back' && this.gameSlide!.count >= 1){
        this.gameSlide!.count -= 1;
        this.gameSlide!.move='translateX(-'+(this.gameSlide!.count*this.gameSlide!.numShow*277) +'px)';
       } 
       else if(status=='forward' && (this.gameSlide!.count+1) < this.gameSlide!.max){
        this.gameSlide!.count += 1;
        this.gameSlide!.move='translateX(-'+(this.gameSlide!.count*this.gameSlide!.numShow*277) +'px)';
       } 
    }
   
 }

 route(path ){
  this.router.navigate([path]);
}

 
}
