import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastService } from 'angular-toastify';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MarketState } from 'src/app/lib/+state/market.reducer';
import { NavFacade } from 'src/app/nav/nav.facade';
import { MainFacade } from '../main.facade';

var moment = require('moment-timezone');
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  public user$: Observable<any>|undefined;
  public login$: Observable<any>|undefined;
  public history$: Observable<any>|undefined;
  public mySell$: Observable<any>|undefined;
  public myActivity$: Observable<any>|undefined;
  public nightMode$: Observable<any>|undefined;
  private destroy$ = new Subject();
  user:any;
  wallet:any;
  walletConnected:any;
  select:any = 1;
  walletDel: any='';
  showWalletDel:boolean = false;
  listView:boolean=true;
  routeId:any;
  constructor(private store: Store<MarketState>, private navFacade:NavFacade, private mainFacade:MainFacade,private activeRoute:ActivatedRoute, private toastr:ToastService) {
    activeRoute.queryParams.subscribe(
      (params) => { 

        if(this.routeId !== params['list']){
          this.routeId = params['list'];
          if(this.routeId == 'wallet'){
              this.select=4;
          }
          else if(this.routeId == 'fav'){
            this.select=6;
          }
          else if(this.routeId == 'mypurchase'){
            this.select=1;
          }
          else if(this.routeId == 'bids'){
            this.select=5;
          }
          else if(this.routeId == 'rafwin'){
            this.select=8;
          }
        }
        else{
          this.routeId = params['list'];
          
          
        }
        
        
      });

  }

  ngOnInit(): void {
    this.setObservables();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  setObservables() {
    this.user$ = this.navFacade.user$;
    this.login$ = this.navFacade.login$;
    this.history$ = this.navFacade.history$;
    this.mySell$ = this.mainFacade.mySell$;
    this.myActivity$ = this.mainFacade.myActivity$;
    this.nightMode$ = this.mainFacade.nightMode$;
    this.user$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 

      if(message){
        this.user = message;
        this.mainFacade.mySell(this.user.Id);
        this.mainFacade.myActivity(this.user.Id)
        if(this.user.Wallets.length > 1){
          this.wallet = 'Mult-Wallet'
        }
        else{
          this.wallet = this.user.Wallets[0].slice(0, 4) + "..." + this.user.Wallets[0].slice(41, 44);
        }

        //this.burnWallet =  this.user.Wallets[0];
      }

    });

    this.login$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 

      if(message){
       this.walletConnected = message;
      }

    });


  }

  formatDate(date){
    var myDatetimeFormat= 'llll';
    var myDatetimeString = moment(date).format(myDatetimeFormat);
    return myDatetimeString
  }

  selectListing(listing){
    //if(this.id )
    this.toastr.info('This item cannot be resold.');
      return;
    if(listing?.CanResell == 0 ){
      this.toastr.info('This item cannot be resold.');
      return;
    }

    if(listing?.StopWalChange == 1){
      this.toastr.info('Wallet collection has completed. This item cannot be resold.');
      return;
    }

    if(listing?.AvailQuantity == 0 && !listing?.SellId){
      this.toastr.info('Quantity available to sell is 0.');
      return;
    }
    this.mainFacade.selectSell(listing);
  }

  showDeleteWallet(wal){
    this.walletDel = wal;
    this.showWalletDel=true;
  }

  deleteWallet(){
    if(this.walletConnected.toLowerCase() === this.walletDel.toLowerCase()){
      this.toastr.info('You cannot remove the wallet you are connected to.');
      return;
    }
    this.mainFacade.deleteWallet(this.user.Id, this.walletDel, this.walletConnected);
    this.showWalletDel=false;
  }

}
