import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-usermarket',
  templateUrl: './usermarket.component.html',
  styleUrls: ['./usermarket.component.scss']
})
export class UsermarketComponent implements OnInit {
  selected:any='all';
  constructor(private router:Router,  private activeRoute:ActivatedRoute) {
    activeRoute.queryParams.subscribe(
      (params) => { 
        if(!params['filter']){}
        else if(this.selected !== params['filter']){
          this.selected = params['filter'];
          
        }
        else{
          this.selected = params['filter'];
        }
        
        
      });

  }


  ngOnInit(): void {
  }

  

  toggleFilter(type){
    this.selected=type;
    this.router.navigate(['/usermarket'], { queryParams: {filter: type}});
  }

}
