import { Action, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import * as MarketActions from './market.action';
import * as moment from 'moment';
import { stat } from 'fs';
export const MarketFeatureKey = 'Market';

export interface MarketState {
  readonly [MarketFeatureKey]: any;
}

export const MarketInitialState: any = {
  error: '',
  user:'',
  loading:false,
  discordData:'',
  discordError:false,
  login:'',
  nfts:[],
  listings:[],
  isNav:false,
  tutorial:0,
  purchase:'',
  bids:'',
  project:{
    Id:1,
    Name:"PG",
    Hero:"https://godjiramarketplace.nyc3.digitaloceanspaces.com/PG-Banner-Dark.png",
    Logo:"https://godjiramarketplace.nyc3.digitaloceanspaces.com/PG_LOGO.png",
    LisCount:0,
    HeroSm:'https://godjiramarketplace.nyc3.digitaloceanspaces.com/PG-Banner-Dark%20-%20sm.png',
    Background:"url('https://godjiramarketplace.nyc3.digitaloceanspaces.com/PG-accent.png')",
    TextColor:'text-[#00aeef]',
    Button:'bg-gradient-to-r from-[#00aeef] to-[#2b388f]'
  },
  projectLis:[],
  hot:[],
  topSpenders:[],
  history:[],
  burn:[],
  trans:[],
  mybids:[],
  selectListing:'',
  pop:'',
  dwc:false,
  gen1:false,
  gen2:false,
  jiraverse:false,
  dark:false,
  clj:false,
  spit:false,
  raffleWinners:[],
  rafflesEntered:[],
  listingLikes:[],
  myLikes:[],
  tokens:0,
  perWallet:[],
  burned:false,
  claim:0,
  discordFailed:false,
  recentBuy:[],
  filters:false,
  nightMode:true,
  showNfts:[],
  notifications:[],
  userListings:[],
  selectUserBuy:'',
  selectSell:'',
  mySell:[],
  allSell:[],
  allActivity:[],
  myActivity:[],
  topsellers:[],
  userProfile:'',
  userList:[],
  eth:0,
  gas:0,
  price:0,
  pg:false
}

const reducer = createReducer(
    MarketInitialState,
//UNIVERSAL
  immerOn(MarketActions.setLoadingSuccess, (state:any, action:any) => {
    state.loading = action.loading;
  }),

  immerOn(MarketActions.getUserSuccess, (state:any, action:any) => {
    state.user = action.user;
    state.history = action.history;
    state.trans = action.trans;
    state.burn = action.burn;
    state.mybids = action.bids;
    state.myLikes = action.myLikes;
    state.claim = action.claim;
    state.notifications = action.notifications;
    state.error = '';
    state.userListings = action.userListings;
    
  }),

  immerOn(MarketActions.getUserFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.user = '';
    state.history = [];
    state.trans = [];
    state.burn = [];
    state.mybids = [];
    state.myLikes = [];
    state.notifications = [];
    state.claim = 0;
    state.userListings = [];
  }),

  immerOn(MarketActions.estJiraPurchaseSuccess, (state:any, action:any) => {
    state.gas = action.gas;
    state.error = '';
    
  }),

  immerOn(MarketActions.estJiraPurchaseFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.gas = 0;
  }),

  immerOn(MarketActions.JiraPurchaseSuccess, (state:any, action:any) => {
    state.eth= action.eth;
    state.error = '';
    state.loading = false;
  }),

  immerOn(MarketActions.JiraPurchaseFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.loading = false;
  }),

  immerOn(MarketActions.userSearchSuccess, (state:any, action:any) => {
    state.userList = action.userList;
    state.error = '';
    
  }),

  immerOn(MarketActions.userSearchFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.userList = [];
  }),

  immerOn(MarketActions.getUserProfileSuccess, (state:any, action:any) => {
    state.userProfile = action.userProfile;
    state.error = '';
    
  }),

  immerOn(MarketActions.getUserProfileFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.userProfile = [];
  }),

  immerOn(MarketActions.nightSuccess, (state:any, action:any) => {
    state.nightMode = action.night;

  }),

  immerOn(MarketActions.changeWalletSuccess, (state:any, action:any) => {
    state.history = action.purchases;
    state.error = '';
  }),

  immerOn(MarketActions.deleteWalletSuccess, (state:any, action:any) => {
    state.user = action.user;
    state.error = '';
  }),

  immerOn(MarketActions.changeWalletFail, (state:any, action:any) => {
    state.error=action.error.message;
    //state.history = [];

  }),

  immerOn(MarketActions.loginSuccess, (state:any, action:any) => {
    state.login = action.wallet;
    //state.eth=action.eth;
    state.error = '';
  }),

  immerOn(MarketActions.loginFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.login = '';
  }),

  immerOn(MarketActions.getUserETHSuccess, (state:any, action:any) => {
    state.eth=action.eth;
    state.error = '';
  }),

  immerOn(MarketActions.getUserETHFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.eth = 0;
  }),

  immerOn(MarketActions.getTokensSuccess, (state:any, action:any) => {
    state.tokens = action.tokens;
    state.perWallet = action.perWallet
    state.error = '';
  }),

  immerOn(MarketActions.getTokensFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.tokens = '';
    state.perWallet = [];
  }),

  immerOn(MarketActions.claimJiraSuccess, (state:any, action:any) => {
    state.claim = 0;
    state.user = action.claim.user;
    state.error = '';
  }),

  immerOn(MarketActions.claimJiraFail, (state:any, action:any) => {
    state.error=action.error.message;
    //state.claim = 0;
    
  }),

  immerOn(MarketActions.burnTokensSuccess, (state:any, action:any) => {
    state.burned = action.success;
    state.error = '';
  }),

  immerOn(MarketActions.burnTokensFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.tokens = '';
  }),

  immerOn(MarketActions.logoutSuccess, (state:any, action:any) => {
    state.login = '';
    state.user = '';
    state.history = '';
    state.error = '';
    state.history = '';
    state.discordData='';
    state.nfts=[];
    state.showNfts=[]
    
  }),

  immerOn(MarketActions.logoutFail, (state:any, action:any) => {
    state.error=action.error.message;

  }),

  immerOn(MarketActions.discordDataSuccess, (state:any, action:any) => {
    state.discordData = action.Discord;
    //state.user = action.Discord.user;
    state.discordError = false;
    state.error = '';
  }),

  immerOn(MarketActions.discordDataFail, (state:any, action:any) => {
    state.discordData ='';
    state.discordError = true;

 
  }),

  immerOn(MarketActions.getNftsSuccess, (state:any, action:any) => {
  state.showNfts=action.Nfts.showNfts;
   state.nfts = action.Nfts.nfts;
   state.dwc = action.Nfts.dwc;
   state.dark = action.Nfts.dark;
   state.gen1 = action.Nfts.gen1;
   state.gen2 = action.Nfts.gen2;
   state.jiraverse = action.Nfts.jiraverse;
   state.spit = action.Nfts.spit;
   state.clj = action.Nfts.clj;
   state.pg= action.Nfts.pg;
    state.error = '';
  }),

  immerOn(MarketActions.getNftsFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.nfts = '';
    state.dwc = false;
    state.dark = false;
    state.pg = false;
  }),

  immerOn(MarketActions.getListingsSuccess, (state:any, action:any) => {
    state.listings = action.listings.listings;
    state.error = '';
  }),

  immerOn(MarketActions.getListingsFail, (state:any, action:any) => {
    state.error=action.error;
    state.listings = [];
 
  }),

  immerOn(MarketActions.getTopSpendersSuccess, (state:any, action:any) => {
    state.topSpenders = action.spenders.topspenders;
    state.error = '';
  }),

  immerOn(MarketActions.getTopSpendersFail, (state:any, action:any) => {
    state.error=action.error;
    state.topSpenders = [];
 
  }),


  immerOn(MarketActions.getRecentBuySuccess, (state:any, action:any) => {
    state.recentBuy = action.recent.recentBuy;
    state.error = '';
  }),

  immerOn(MarketActions.getRecentBuyFail, (state:any, action:any) => {
    state.error=action.error;
    state.recentBuy = [];
 
  }),

  immerOn(MarketActions.showFiltersSuccess, (state:any, action:any) => {
    
    state.filters = action.filters;

  }),


  immerOn(MarketActions.timedListingSuccess, (state:any, action:any) => {
    let newList = [];
    action.old.forEach((ls)=>{
      let found = action.listing.listings.find((x)=>{
        return x.Id === ls.Id;
      })
      if(found ){
        newList.push(found);
      }
      else{
        newList.push(ls);
      }
    })
    state.listings = newList;
    state.error = '';
  }),

  immerOn(MarketActions.timedListingFail, (state:any, action:any) => {
    state.error=action.error;
    //state.listings = [];
 
  }),

  immerOn(MarketActions.getEndedListingsSuccess, (state:any, action:any) => {
    state.listings = action.listings.listings;
    state.error = '';
  }),

  immerOn(MarketActions.getEndedListingsFail, (state:any, action:any) => {
    state.error=action.error;
    state.listings = [];
 
  }),

  immerOn(MarketActions.getHotListingsSuccess, (state:any, action:any) => {
  
    state.hot = action.listings.listings;
    //state.topSpenders = action.listings.topspenders;
    state.error = '';
  }),

  immerOn(MarketActions.getHotListingsFail, (state:any, action:any) => {
    state.error=action.error;
    state.hot = [];
    //state.topSpenders = [];
  }),

  immerOn(MarketActions.purchaseSuccess, (state:any, action:any) => {
    state.purchase = true;
    state.error = '';
  }),

  immerOn(MarketActions.purchaseFail, (state:any, action:any) => {
    state.error=action.error;
    state.purchase = '';
 
  }),

  immerOn(MarketActions.placeBidSuccess, (state:any, action:any) => {
    state.bid = action.bid.amount;
    state.error = '';
  }),

  immerOn(MarketActions.placeBidFail, (state:any, action:any) => {
    state.error=action.error;
    state.bid = '';
 
  }),

  immerOn(MarketActions.selectListingSuccess, (state:any, action:any) => {
    if(action.listing.listing){
      let listingNew ={
        Active:action.listing.listing.Active,
        AuctionEnd:action.listingOG.AuctionEnd,
        CurrentPrice:action.listing.listing.CurrentPrice,
        Description:action.listingOG.Description,
        EndDate:action.listingOG.EndDate,
        HasEnd:action.listingOG.HasEnd,
        HotListing:action.listingOG.HotListing,
        Id:action.listingOG.Id,
        ImageUrl:action.listingOG.ImageUrl,
        InitialBalance:action.listingOG.InitialBalance,
        ListingLinks:action.listingOG.ListingLinks,
        ListingName:action.listingOG.ListingName,
        MaxPurchasePerUser:action.listingOG.MaxPurchasePerUser,
        Preview:action.listingOG.Preview,
        Price:action.listing.listing.Price,
        RaffleEndDate:action.listingOG.RaffleEndDate,
        RemainingBalance:action.listing.listing.RemainingBalance,
        TopBid:action.listing.listing.TopBid,
        Type:action.listingOG.Type,
        TypeName:action.listingOG.TypeName,
        totalBids:action.listing.listing.totalBids,
        GodjiraType:action.listingOG.GodjiraType,
        DAPriceDec:action.listingOG.DAPriceDec,
        DATimeInc:action.listingOG.DATimeInc,
        DAEndPrice:action.listingOG.DAEndPrice,
        AuctionStart:action.listingOG.AuctionStart,
        DTC:action.listingOG.DTC,
        seconds:action.listing.seconds,
        days:action.listing.days,
        notStarted:action.listing.notStarted,
        ProjId:action.listingOG.ProjId
      }
      state.selectListing = listingNew;
    }
    else{
      state.selectListing = '';
    }
    state.error = '';
  }),

  immerOn(MarketActions.selectListingFail, (state:any, action:any) => {
    state.error=action.error;
    state.selectListing = '';
 
  }),
  

  immerOn(MarketActions.likeListingSuccess, (state:any, action:any) => {
    state.listingLikes = action.listing.likes;
    state.myLikes = action.listing.myLikes;
    state.error = '';
  }),

  immerOn(MarketActions.likeListingFail, (state:any, action:any) => {
    state.error=action.error;
    state.listingLikes = [];
 
  }),

  immerOn(MarketActions.getRaffleWinSuccess, (state:any, action:any) => {
    state.raffleWinners = action.raffleWinners;
    state.rafflesEntered = action.raffles;
    state.error = '';
  }),

  immerOn(MarketActions.getRaffleWinFail, (state:any, action:any) => {
    state.error=action.error;
    state.raffleWinners = [];
    state.rafflesEntered = [];
 
  }),

  

  immerOn(MarketActions.setNavSuccess, (state:any, action:any) => {
    state.isNav=action.isNav;
   
  }),

  immerOn(MarketActions.changeProjectSuccess, (state:any, action:any) => {
    state.project=action.project;
   
  }),

  immerOn(MarketActions.popupSuccess, (state:any, action:any) => {
    state.pop=action.pop;
   
  }),

  immerOn(MarketActions.LoadProjectsSuccess, (state:any, action:any) => {
    state.projectLis=action.projects;
    state.price=Number(action.price);
   
  }),

  immerOn(MarketActions.LoadProjectsFail, (state:any, action:any) => {
    state.projects=[];
   
  }),

  immerOn(MarketActions.mySellSuccess, (state:any, action:any) => {
    state.mySell=action.items;
   
  }),

  immerOn(MarketActions.mySellFail, (state:any, action:any) => {
    state.mySell=[];
   
  }),

  immerOn(MarketActions.allSellSuccess, (state:any, action:any) => {
    state.allSell=action.items;
    state.topSellers=action.top;
  }),

  immerOn(MarketActions.allSellFail, (state:any, action:any) => {
    state.allSell=[];
   
  }),

  immerOn(MarketActions.selectSellSuccess, (state:any, action:any) => {
    state.selectSell=action.item;
   
  }),


  immerOn(MarketActions.selectUserBuySuccess, (state:any, action:any) => {
   state.selectUserBuy = action.buy;
   
  }),


  immerOn(MarketActions.listSellSuccess, (state:any, action:any) => {
    state.mySell=action.items.userListing;
    state.history=action.items.history;
    state.selectSell='';
   
  }),

  immerOn(MarketActions.cancelSellSuccess, (state:any, action:any) => {
    state.mySell=action.items.userListing;
    state.history=action.items.history;
    state.selectSell='';
  }),

  immerOn(MarketActions.myActivitySuccess, (state:any, action:any) => {
    state.myActivity=action.activity;
   
  }),

  immerOn(MarketActions.myActivityFail, (state:any, action:any) => {
    state.myActivity=[];
   
  }),

  immerOn(MarketActions.allActivitySuccess, (state:any, action:any) => {
    state.allActivity=action.activity;
   
  }),

  immerOn(MarketActions.allActivityFail, (state:any, action:any) => {
    state.allActivity=[];
  }),

  immerOn(MarketActions.purchaseUserListingSuccess, (state:any, action:any) => {
    //state.allSell=action.items;
    state.selectUserBuy = null;
   
  }),

  immerOn(MarketActions.purchaseUserListingFail, (state:any, action:any) => {
    //state.allSell =[];
  })
   


  /*immerOn(MarketActions.startTutorialSuccess, (state:any, action:any) => {
    state.tutorial=action.data;
    
   
  }),*/

);

export function MarketReducer(state: any, action: Action): any {
    return reducer(state, action);
}