import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NavFacade } from 'src/app/nav/nav.facade';
import { MainFacade } from 'src/app/pages/main.facade';

@Component({
  selector: 'app-topsellers',
  templateUrl: './topsellers.component.html',
  styleUrls: ['./topsellers.component.scss']
})
export class TopsellersComponent implements OnInit, OnDestroy {
  public topsellers$: Observable<any>|undefined;
  private destroy$ = new Subject();
  user:any;
  wallet:any;
  constructor(private navFacade:NavFacade, private mainFacade:MainFacade) {}

  ngOnInit(): void {
    this.setObservables();
    console.log('here')
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  setObservables() {
    this.topsellers$ = this.mainFacade.topSellers$;
    this.mainFacade.allActivity();
  }

}
