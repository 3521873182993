import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { from, of } from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as MarketActions from './market.action';
import { Action } from 'rxjs/internal/scheduler/Action';
import { MarketState } from './market.reducer';
import { MainService } from '../services/main.service';
import { Web3Service } from '../services/web3.service';
import { AuthService } from '../services/auth.service';
import { CookieService } from '../services/cookie.service';
import { ToastService } from 'angular-toastify';
var moment = require('moment-timezone');


@Injectable()
export class MarketEffects {
  tutorial:number=0;
   constructor(
    private actions$: Actions,
    private store: Store<MarketState>,
    private mainService: MainService,
    private web3:Web3Service,
    private authService:AuthService,
    private cookie:CookieService,
    private toastr:ToastService
  ) {}

  login = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.login),
      concatMap((action: any) => from(this.web3.initWeb3()).pipe(
        map((data: any) =>{
            if(data.error){
                this.toastr.error("ERROR OCCURED ON WALLET CONNECT.");
                return MarketActions.loginFail({error:{message:"ERROR OCCURED ON WALLET CONNECT."}});
            }
            
            this.store.dispatch(MarketActions.getUser({address:data.walletSet}));
            this.store.dispatch(MarketActions.getUserETH({address:data.walletSet}));
            return MarketActions.loginSuccess({wallet: data.walletSet});
        }),
        catchError((error) => {
          
          return of(MarketActions.loginFail({error}));
        })

        )
      )
    );
    }
  );

  changeWallet= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.changeWallet),
      concatMap((action: any) => this.mainService.changeWallet(action.pid, action.userid, action.wallet).pipe(
        map((data: any) => {
        
          return MarketActions.changeWalletSuccess({
            purchases: data.purchases
          })
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.changeWalletFail({error}));
        })

        )
      )
    );
    }
  );

  getETH= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.getUserETH),
      concatMap((action: any) => this.mainService.getETH(action.address).pipe(
        map((data: any) => {
        
          return MarketActions.getUserETHSuccess({
            eth: data.eth
          })
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.getUserETHFail({error}));
        })

        )
      )
    );
    }
  );

  deleteWallet= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.deleteWallet),
      concatMap((action: any) => this.mainService.deleteWallet(action.userid, action.wallet).pipe(
        map((data: any) => {

          this.store.dispatch(MarketActions.getUser({address:action.walletSet}));
          return MarketActions.deleteWalletSuccess({
            wallets: data.wallets
          })
        }),
        catchError((error) => {
          this.toastr.error(error);
          return of(MarketActions.deleteWalletFail({error}));
        })

        )
      )
    );
    }
  );

  userSearch= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.userSearch),
      concatMap((action: any) => this.mainService.userSearch(action.search).pipe(
        map((data: any) => {
        
          return MarketActions.userSearchSuccess({
            userList: data.userListing
          })
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.userSearchFail({error}));
        })

        )
      )
    );
    }
  );


  userProfile= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.getUserProfile),
      concatMap((action: any) => this.mainService.getUserProfile(action.userId).pipe(
        map((data: any) => {
        
          return MarketActions.getUserProfileSuccess({
            userProfile: data.userProfile
          })
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.getUserProfileFail({error}));
        })

        )
      )
    );
    }
  );

  tokenBalance = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.getTokens),
      concatMap((action: any) => from(this.web3.getJiraBalance(action.address)).pipe(
        map((data: any) =>{
            if(data.error){
              this.toastr.error("ERROR OCCURED WHILE GETTING JIRA.");
                return MarketActions.getTokensFail({error:{message:"ERROR OCCURED WHILE GETTING JIRA."}});
            }
            
            return MarketActions.getTokensSuccess({tokens: data.tokens, perWallet:data.perWallet});
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.getTokensFail({error}));
        })

        )
      )
    );
    }
  );

  estJiraPurchase = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.estJiraPurchase),
      concatMap((action: any) => from(this.web3.estimateJiraPurchase(action.amount)).pipe(
        map((data: any) =>{
            if(data.error){
              this.toastr.error("ERROR OCCURED WHILE ESTIMATING GAS.");
                return MarketActions.estJiraPurchaseFail({error:{message:"ERROR OCCURED WHILE ESTIMATING GAS."}});
            }
            
            return MarketActions.estJiraPurchaseSuccess({gas: data});
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.estJiraPurchaseFail({error}));
        })

        )
      )
    );
    }
  );

  JiraPurchase = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.JiraPurchase),
      concatMap((action: any) => from(this.web3.purchaseJira(action.amount)).pipe(
        map((data: any) =>{
            if(data.error){
              this.toastr.error("ERROR OCCURED WHILE PURCHASING JIRAX.");
              return MarketActions.JiraPurchaseFail({error:{message:"ERROR OCCURED WHILE PURCHASING JIRA."}});
            }

            this.store.dispatch(MarketActions.getUser({address:data.wallet}));
            this.store.dispatch(MarketActions.getUserETH({address:data.wallet}));
            return MarketActions.JiraPurchaseSuccess({eth:null});
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.JiraPurchaseFail({error}));
        })

        )
      )
    );
    }
  );

  burnJira = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.burnTokens),
      concatMap((action: any) => from(this.web3.burnJira(action.amount, action.address)).pipe(
        map((data: any) =>{
            /*if(data.error){
                return MarketActions.burnTokensFail({error:{message:"ERROR OCCURED WHILE BURN JIRA."}});
            }*/
            this.store.dispatch(MarketActions.getUser({address:action.address}));
            
            return MarketActions.burnTokensSuccess({success: data});
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.burnTokensFail({error}));
        })

        )
      )
    );
    }
  );

  logout = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.logout),
      concatMap((action: any) => from(this.web3.logout()).pipe(
        map((data: any) =>{
            if(data.error){
              this.toastr.error("ERROR OCCURED ON WALLET DISCONNECT.");
                return MarketActions.logoutFail({error:{message:"ERROR OCCURED ON WALLET DISCONNECT."}});
            }
            this.store.dispatch(MarketActions.login({provider:null}));
            return MarketActions.logoutSuccess({wallet: data});
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.loginFail({error}));
        })

        )
      )
    );
    }
  );

  loadUser= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.getUser),
      concatMap((action: any) => this.mainService.getUser(action.address).pipe(
        map((data: any) => {
       
          let user;
          let history;
          let burn;
          let trans;
          let bids;
          let myLikes;
          let claim;
          let notifications;
          let userListings;
          if(data){
            user = data.user;
            history = data.purchases;
            burn = data.burn;
            trans = data.trans;
            bids = data.bids;
            myLikes = data.myLikes;
            claim=data.claim;
            notifications=data.notifications;
            userListings=data.userListings;
          }


          //this.store.dispatch(MarketActions.getTokens({address:data.user.Wallets}));
          this.store.dispatch(MarketActions.getNfts({data:action.address}));
          return MarketActions.getUserSuccess({
            user: user,
            history: history,
            burn:burn,
            trans:trans,
            bids:bids,
            myLikes:myLikes,
            claim:claim,
            notifications:notifications,
            userListings:userListings
          })
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.getUserFail({error}));
        })

        )
      )
    );
    }
  );

  updateAvatar= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.updateAvatar),
      concatMap((action: any) => this.mainService.updateAvatar(action.id, action.url).pipe(
        map((data: any) => {
          this.store.dispatch(MarketActions.getUser({address:action.address}))
          return MarketActions.updateAvatarSuccess({
            status: data,
          })
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.updateAvatarFail({error}));
        })

        )
      )
    );
    }
  );

  loadListings= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.getListings),
      concatMap((action: any) => this.mainService.getListings(action.data).pipe(
        map((data: any) => {
          data.listings.forEach(element => {
            let seconds = 0;
            let days;
            if(element.Type === 'Buy Now'){
              seconds = this.getTime(element.EndDate);
              days = this.getDays(element.EndDate);
            }
            else if (element.Type === 'Auction'){
              seconds = this.getTime(element.AuctionEnd);
              days = this.getDays(element.AuctionEnd);
              
            }
            else if (element.Type === 'Raffle'){
              seconds = this.getTime(element.RaffleEndDate);
              days = this.getDays(element.RaffleEndDate);
              
            }
            let notStarted=false;
            if(element.Preview){
              if(this.getTime(element.PostTime) > 0){
                days = this.getDays(element.PostTime);
                seconds = this.getTime(element.PostTime);
                notStarted = true;
              }
              
            }
            
            element.seconds = seconds;
            element.days = days;
            element.notStarted = notStarted;
          });
          return MarketActions.getListingsSuccess({
            listings: data
          })
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.getListingsFail({error}));
        })

        )
      )
    );
    }
  );

  loadHotListings= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.getHotListings),
      concatMap((action: any) => this.mainService.getHotListings(action.data).pipe(
        map((data: any) => {
          data.listings.forEach(element => {
            let seconds = 0;
            let days;
            if(element.Type === 'Buy Now'){
              seconds = this.getTime(element.EndDate);
              days = this.getDays(element.EndDate);
            }
            else if (element.Type === 'Auction'){
              seconds = this.getTime(element.AuctionEnd);
              days = this.getDays(element.AuctionEnd);
              
            }
            else if (element.Type === 'Raffle'){
              seconds = this.getTime(element.RaffleEndDate);
              days = this.getDays(element.RaffleEndDate);
              
            }
            let notStarted=false;
            if(element.Preview){
              if(this.getTime(element.PostTime) > 0){
                days = this.getDays(element.PostTime);
                seconds = this.getTime(element.PostTime);
                notStarted = true;
              }
              
            }
            element.seconds = seconds;
            element.days = days;
            element.notStarted = notStarted;
          });
          /*this.store.dispatch(MarketActions.getTopWinners({data:null}));*/
          return MarketActions.getHotListingsSuccess({
            listings: data
          })
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.getHotListingsFail({error}));
        })

        )
      )
    );
    }
  );

  loadEndedListings= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.getEndedListings),
      concatMap((action: any) => this.mainService.getEndedListings(action.data).pipe(
        map((data: any) => {
          data.listings.forEach(element => {
            let seconds = 0;
            let days;
            if(element.Type === 'Buy Now'){
              seconds = this.getTime(element.EndDate);
              days = this.getDays(element.EndDate);
            }
            else if (element.Type === 'Auction'){
              seconds = this.getTime(element.AuctionEnd);
              days = this.getDays(element.AuctionEnd);
              
            }
            else if (element.Type === 'Raffle'){
              seconds = this.getTime(element.RaffleEndDate);
              days = this.getDays(element.RaffleEndDate);
              
            }
            let notStarted=false;
            if(element.Preview){
              if(this.getTime(element.PostTime) > 0){
                days = this.getDays(element.PostTime);
                seconds = this.getTime(element.PostTime);
                notStarted = true;
              }
              
            }
            element.seconds = seconds;
            element.days = days;
            element.notStarted = notStarted;
          });
          /*this.store.dispatch(MarketActions.getTopWinners({data:null}));*/
          return MarketActions.getEndedListingsSuccess({
            listings: data
          })
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.getEndedListingsFail({error}));
        })

        )
      )
    );
    }
  );

  timedListing = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.timedListing),
      concatMap((action: any) => this.mainService.timedListings(action.listingIds).pipe(
        map((data: any) => {
          data.listings.forEach(element => {
            let seconds = 0;
            let days;
            if(element.Type === 'Buy Now'){
              seconds = this.getTime(element.EndDate);
              days = this.getDays(element.EndDate);
            }
            else if (element.Type === 'Auction'){
              seconds = this.getTime(element.AuctionEnd);
              days = this.getDays(element.AuctionEnd);
              
            }
            else if (element.Type === 'Raffle'){
              seconds = this.getTime(element.RaffleEndDate);
              days = this.getDays(element.RaffleEndDate);
              
            }
            let notStarted=false;
            if(element.Preview){
              if(this.getTime(element.PostTime) > 0){
                days = this.getDays(element.PostTime);
                seconds = this.getTime(element.PostTime);
                notStarted = true;
              }
              
            }
            element.seconds = seconds;
            element.days = days;
            element.notStarted = notStarted;
          });
          return MarketActions.timedListingSuccess({
            listing: data,
            old:action.showedListings
          })
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.timedListingFail({error}));
        })

        )
      )
    );
    }
  );

  claimJira = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.claimJira),
      concatMap((action: any) => this.mainService.claim(action.userIds).pipe(
        map((data: any) => {
          /*this.store.dispatch(MarketActions.getTopWinners({data:null}));*/
          return MarketActions.claimJiraSuccess({
            claim: data
          })
        }),
        catchError((error) => {
          //this.toastr.error(error.error.message);
          return of(MarketActions.claimJiraFail({error}));
        })

        )
      )
    );
    }
  );

  selectListing= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.selectListing),
      concatMap((action: any) => this.mainService.getListingUpdate(action.listing ? action.listing.Id : 0).pipe(
        map((data: any) => {
          if(action.listing){
            let seconds = 0;
            let days;
            if(action.listing.Type === 'Buy Now'){
              seconds = this.getTime(action.listing.EndDate);
              days = this.getDays(action.listing.EndDate);
            }
            else if (action.listing.Type === 'Auction'){
              seconds = this.getTime(action.listing.AuctionEnd);
              days = this.getDays(action.listing.AuctionEnd);
              
            }
            else if (action.listing.Type === 'Raffle'){
              seconds = this.getTime(action.listing.RaffleEndDate);
              days = this.getDays(action.listing.RaffleEndDate);
              
            }
            let notStarted=false;
            if(action.listing.Preview){
              if(this.getTime(action.listing.PostTime) > 0){
                days = this.getDays(action.listing.PostTime);
                seconds = this.getTime(action.listing.PostTime);
                notStarted = true;
              }
              
            }
            data.seconds = seconds;
            data.days = days ? days : 0;
            data.notStarted = notStarted;
          }
            

          return MarketActions.selectListingSuccess({
            listing: data,
            listingOG:action.listing
          })
        }),
        catchError((error) => {
          //console.log(error)
          
          return of(MarketActions.selectListingFail({error}));
        })

        )
      )
    );
    }
  );

  loadProjects= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.LoadProjects),
      concatMap((action: any) => this.mainService.getProjects().pipe(
        map((data: any) => {
          
          return MarketActions.LoadProjectsSuccess({
            projects:data.projects,
            price:data.price
          })
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.LoadProjectsFail({error}));
        })

        )
      )
    );
    }
  );

  likeListing= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.likeListing),
      concatMap((action: any) => this.mainService.likeListing(action.userId,action.listingId, action.typeVal).pipe(
        map((data: any) => {
          
          return MarketActions.likeListingSuccess({
            listing: data
          })
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.likeListingFail({error}));
        })

        )
      )
    );
    }
  );

  raffleWinners= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.getRaffleWin),
      concatMap((action: any) => this.mainService.getRaffleWinners(action.userId).pipe(
        map((data: any) => {
          
          return MarketActions.getRaffleWinSuccess({
            raffleWinners: data.raffleWinners,
            raffles:data.raffles
          })
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.getRaffleWinFail({error}));
        })

        )
      )
    );
    }
  );

  loadTopSpenders= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.getTopSpenders),
      concatMap((action: any) => this.mainService.getTopSpenders().pipe(
        map((data: any) => {
        
          return MarketActions.getTopSpendersSuccess({
            spenders: data
          })
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.getTopSpendersFail({error}));
        })

        )
      )
    );
    }
  );

  handleDiscordData = createEffect(() =>{
    return this.actions$.pipe(
      ofType(MarketActions.discordData),
      concatMap((action: any) => this.authService.getDiscordData(action.code, action.wallet).pipe(
        map((data: any) => {

          /*if(data){
            this.cookie.setCookie('token',data.data.access_token, data.data.expires_in);
          }*/
         
          this.store.dispatch(MarketActions.getUser({address:action.wallet}));
          return MarketActions.discordDataSuccess({
            Discord: data
          })
        }
        ),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.discordDataFail({error}));
        })

        )
      )
    );
  });

  getNFTs = createEffect(() =>{
    return this.actions$.pipe(
      ofType(MarketActions.getNfts),
      concatMap((action: any) => this.mainService.getNFTs(action.data).pipe(
        map((data: any) => {

          return MarketActions.getNftsSuccess({Nfts: data});
        }
        ),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.getNftsFail({error}));
        })

        )
      )
    );
  });
/*
  getNFTs = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.getNfts),
      concatMap((action: any) => from(this.web3.getNFTsWallet(action.data)).pipe(
        map((data: any) =>{
            if(data.error){
              this.toastr.error("ERROR OCCURED ON WALLET CONNECT.");
                return MarketActions.getNftsFail({error:{message:"ERROR OCCURED ON WALLET CONNECT."}});
            }
     
            //this.store.dispatch(MarketActions.stakeNfts({data:data}));
            return MarketActions.getNftsSuccess({Nfts: data});
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.loginFail({error}));
        })

        )
      )
    );
    }
  );*/

  purchase = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.purchase),
      concatMap((action: any) => from(this.mainService.makePurchase(action.userId, action.listingId, action.quantity, action.wallet, action.discordId)).pipe(
        map((data: any) =>{
          this.toastr.success("Purchase Successful!");
            this.store.dispatch(MarketActions.getUser({address:action.address}));
            return MarketActions.purchaseSuccess({purchase:data});
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.purchaseFail({error}));
        })

        )
      )
    );
    }
  );

  purchaseUserListing = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.purchaseUserListing),
      concatMap((action: any) => from(this.mainService.purchaseUserListing(action.userId, action.listingId, action.wallet)).pipe(
        map((data: any) =>{
          this.toastr.success("Purchase Successful!");
            this.store.dispatch(MarketActions.getUser({address:action.wallet}));
            return MarketActions.purchaseUserListingSuccess({items:data});
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.purchaseUserListingFail({error}));
        })

        )
      )
    );
    }
  );

  placeBid = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.placeBid),
      concatMap((action: any) => from(this.mainService.placeBid(action.userId, action.listingId, action.amount, action.wallet)).pipe(
        map((data: any) =>{
          this.toastr.success("Bid was placed!");
            return MarketActions.placeBidSuccess({bid:data});
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.placeBidFail({error}));
        })

        )
      )
    );
    }
  );

  setNav = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.setNav),
      map((action: any) => {
        return MarketActions.setNavSuccess({isNav:action.data});
      }
    )
    )
  });

  setLoading = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.setLoading),
      map((action: any) => {
        return MarketActions.setLoadingSuccess({loading:action.data});
      }
    )
    )
  });

  nightMode = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.night),
      map((action: any) => {
        return MarketActions.nightSuccess({night:action.night});
      }
    )
    )
  });

  setProject = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.changeProject),
      map((action: any) => {
        //this.store.dispatch(MarketActions.getRecentBuy({data:action.project.Id}));
        return MarketActions.changeProjectSuccess({project:action.project});
      }
    )
    )
  });

  popup = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.popup),
      map((action: any) => {
        return MarketActions.popupSuccess({pop:action.pop});
      }
    )
    )
  });

  recentBuy= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.getRecentBuy),
      concatMap((action: any) => this.mainService.getRecentBuy(action.data).pipe(
        map((data: any) => {
        
          return MarketActions.getRecentBuySuccess({
            recent: data
          })
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.getRecentBuyFail({error}));
        })

        )
      )
    );
    }
  );

  getselectUserBuy = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.selectUserBuy),
      map((action: any) => {
        console.log(action.buy)
        return MarketActions.selectUserBuySuccess({
          buy: action.buy
        })
      }
    )
    )
  });

  showFilters = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.showFilters),
      map((action: any) => {
        //this.store.dispatch(MarketActions.getRecentBuy({data:action.project.Id}));
        return MarketActions.showFiltersSuccess({filters:action.filters});
      }
    )
    )
  });

  selectSell = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.selectSell),
      map((action: any) => {
        return MarketActions.selectSellSuccess({
          item: action.item
        })
      }
    )
    )
  });

  

  mySell= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.mySell),
      concatMap((action: any) => this.mainService.getMySell(action.userId).pipe(
        map((data: any) => {
          
          return MarketActions.mySellSuccess({
            items: data?.userListing
          })
        }),
        catchError((error) => {
          //console.log(error)
          
          return of(MarketActions.mySellFail({error}));
        })

        )
      )
    );
    }
  );

  allSell= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.allSell),
      concatMap((action: any) => this.mainService.getAvailableSell(action.typeFilter, action.search).pipe(
        map((data: any) => {

          return MarketActions.allSellSuccess({
            items: data?.sellListing,
            top:data?.top
          })
        }),
        catchError((error) => {
          //console.log(error)
          
          return of(MarketActions.allSellFail({error}));
        })

        )
      )
    );
    }
  );

  myActivity= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.myActivity),
      concatMap((action: any) => this.mainService.getMyActivity(action.userId).pipe(
        map((data: any) => {
          
          return MarketActions.myActivitySuccess({
            activity: data?.userListing
          })
        }),
        catchError((error) => {
          //console.log(error)
          
          return of(MarketActions.myActivityFail({error}));
        })

        )
      )
    );
    }
  );

  allActivity= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.allActivity),
      concatMap((action: any) => this.mainService.getAllActivity().pipe(
        map((data: any) => {

          return MarketActions.allActivitySuccess({
            activity: data?.userListing
          })
        }),
        catchError((error) => {
          //console.log(error)
          
          return of(MarketActions.allActivityFail({error}));
        })

        )
      )
    );
    }
  );

  listItem= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.listSell),
      concatMap((action: any) => this.mainService.listSell(action.listing, action.userId).pipe(
        map((data: any) => {
          
          return MarketActions.listSellSuccess({
            items: data
          })
        }),
        catchError((error) => {
          this.toastr.error(error?.error?.message);
          return of(MarketActions.listSellFail({error}));
        })

        )
      )
    );
    }
  );

  cancelItem= createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.cancelSell),
      concatMap((action: any) => this.mainService.cancelSell(action.listId, action.userId).pipe(
        map((data: any) => {
          
          return MarketActions.cancelSellSuccess({
            items: data
          })
        }),
        catchError((error) => {
          this.toastr.error(error?.error?.message);
          return of(MarketActions.cancelSellFail({error}));
        })

        )
      )
    );
    }
  );


  
  getTime(date:any){
    if(!date){
      return '0';
    }
    var d = new Date();
    var dP = new Date(date);
    var myDatetimeFormat= "YYYY-MM-DD hh:mm:ss A";
    var myDatetimeString = moment.utc(d).format(myDatetimeFormat);/// tz(myTimezone).format(myDatetimeFormat);
    var myDatetimeStringPost = moment.utc(dP).format(myDatetimeFormat);
    let duration = moment.duration(moment(myDatetimeStringPost).diff(moment(myDatetimeString)));

    if(moment(myDatetimeString).isSameOrAfter(moment(myDatetimeStringPost))){
      return 0;
    }
    let seconds = duration.asSeconds();
  
    return !seconds || seconds > 0 ? seconds  : '0';
  }

  getDays(date:any){
    if(!date){
      return '0';
    }
    var d = new Date();
    var dP = new Date(date);
    var myDatetimeFormat= "YYYY-MM-DD hh:mm:ss A";
    var myDatetimeString = moment.utc(d).format(myDatetimeFormat);/// tz(myTimezone).format(myDatetimeFormat);
    var myDatetimeStringPost = moment.utc(dP).format(myDatetimeFormat);
    let duration = moment.duration(moment(myDatetimeStringPost).diff(moment(myDatetimeString)));
   
    if(moment(myDatetimeString).isSameOrAfter(moment(myDatetimeStringPost))){
      return 0;
    }
    let seconds = duration.asSeconds();
 
    return !seconds || seconds > 0 ? Math.floor(seconds/60/60/24)  : 0;
  }

}