import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NavFacade } from 'src/app/nav/nav.facade';
var moment = require('moment-timezone');
@Component({
  selector: 'app-bids',
  templateUrl: './bids.component.html',
  styleUrls: ['./bids.component.scss']
})
export class BidsComponent implements OnInit {
  public mybids$: Observable<any>|undefined;

  constructor(private navFacade:NavFacade) { }

  ngOnInit(): void {
    this.setSubscription();
  }

  setSubscription(){
    this.mybids$ = this.navFacade.mybids$;
  }

  formatDate(date){
    var myDatetimeFormat= 'llll';
    var myDatetimeString = moment(date).format(myDatetimeFormat);
    return myDatetimeString
  }
}
