import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/lib/entities/User';
import { NavFacade } from 'src/app/nav/nav.facade';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit, OnDestroy {
  public myLikes$: Observable<any>|undefined;
  public error$: Observable<any>|undefined;
  public user$: Observable<any>|undefined;
  private destroy$ = new Subject();
  user:User;
  constructor(private navFacade:NavFacade, private router:Router) { }

  ngOnInit(): void {
    this.setObservables();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
  setObservables() {
    this.error$ = this.navFacade.error$;
    this.user$ = this.navFacade.user$;
    this.myLikes$ = this.navFacade.myLikes$;
    

    this.user$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 

      if(message){
        this.user = message;
        
      }

    });

    this.myLikes$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 

      if(message){
        console.log(message)
        
      }

    });


    /*this.nightMode$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.night = message
        
      }

    });*/
   

    

  }

  like(listingId, type){
    this.navFacade.likesListing(this.user.Id, listingId,type);
  }

  navToList(ele, id, pid, gtype ){
    //this.mainfacade.selectListing(id);
    if(ele?.target && (ele.target as Element).className && (ele.target as Element).className.indexOf('like') >= 0){
      return; 
    }
    this.navFacade.getPopup('');
    this.router.navigate(['market'], { queryParams: {id: id, project:pid, gtype:gtype}});
  }

}
