import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hiddenroom',
  templateUrl: './hiddenroom.component.html',
  styleUrls: ['./hiddenroom.component.scss'],
  host:{
    '(document:mousemove)':'mouseMove($event)',
  }
})
export class HiddenroomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  mouseMove(e){

    var valueX = e.pageX / window.innerHeight;

      document.getElementById('back1').style.webkitTransform = 'translateX(' + valueX * -.25+'%)';
      document.getElementById('back1').style.transform = 'translateX(' + valueX * -.25+'%)';
      document.getElementById('back2').style.transform = 'translateX(' + valueX * .25 +'%)';
      document.getElementById('back2').style.webkitTransform = 'translateX(' + valueX * .25 +'%)';
      document.getElementById('back3').style.transform = 'translateX(' + valueX * -.25 +'%)';
      document.getElementById('back3').style.webkitTransform = 'translateX(' + valueX * -.25 +'%)';
      document.getElementById('back4').style.webkitTransform = 'translateX(' + valueX * .25 +  '%)';
      document.getElementById('back4').style.transform = 'translateX(' + valueX * .25 +  '%) ';
  }

}
