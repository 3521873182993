import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { CountdownGlobalConfig, CountdownModule } from 'ngx-countdown';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MainComponent } from "./main/main.component";
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { UsermarketComponent } from './usermarket/usermarket.component';
import { ListingsComponent } from "../component/listings/listings.component";
import { MarketactivityComponent } from "../component/marketactivity/marketactivity.component";
import { TopsellersComponent } from "../component/topsellers/topsellers.component";
import { GamedashboardComponent } from './gamedashboard/gamedashboard.component';
import { UserComponent } from './user/user.component';
import { WalletbarComponent } from "../component/walletbar/walletbar.component";
import { HiddenroomComponent } from './hiddenroom/hiddenroom.component';
import { BidsComponent } from "../component/bids/bids.component";
import { BalancehistoryComponent } from "../component/balancehistory/balancehistory.component";
import { FavoritesComponent } from "../component/favorites/favorites.component";
import { WalletsComponent } from "../component/wallets/wallets.component";
import { PurchasesComponent } from "../component/purchases/purchases.component";
import { RafflesComponent } from "../component/raffles/raffles.component";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CarouselModule,
    CountdownModule
  ],
  declarations: [
    MainComponent,
    ProfileComponent,
    HomeComponent,
    UsermarketComponent,
    ListingsComponent,
    MarketactivityComponent,
    TopsellersComponent,
    GamedashboardComponent,
    UserComponent,
    WalletbarComponent,
    HiddenroomComponent,
    BidsComponent,
    BalancehistoryComponent,
    FavoritesComponent,
    WalletsComponent,
    PurchasesComponent,
    RafflesComponent
  ],
  exports: [

  ],
  providers: [
  ]
})
export class PagesModule { }
