import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'angular-toastify';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../lib/services/api.service';
import { NavFacade } from '../nav/nav.facade';
import { MainFacade } from '../pages/main.facade';

@Component({
  selector: 'app-selectbar',
  templateUrl: './selectbar.component.html',
  styleUrls: ['./selectbar.component.scss']
})
export class SelectbarComponent implements OnInit, OnDestroy {
  public error$: Observable<any>|undefined;
  public user$: Observable<any>|undefined;
  public nfts$: Observable<any>|undefined;
  public purchase$: Observable<any>|undefined;
  public bid$: Observable<any>|undefined;
  public selectListing$: Observable<any>|undefined;
  public myLikes$: Observable<any>|undefined;
  public project$: Observable<any>|undefined;
  public dwc$: Observable<any>|undefined;
  public gen1$: Observable<any>|undefined;
  public gen2$: Observable<any>|undefined;
  public jiraverse$: Observable<any>|undefined;
  public dark$: Observable<any>|undefined;
  public clj$: Observable<any>|undefined;
  public projectLis$: Observable<any>|undefined;
  public nightMode$: Observable<any>|undefined;
  daCountDown:any;
  projectLis:any[];
  myLikes:any[];
  selectListing:any;
  baseListings:any[] = [];
  private destroy$ = new Subject();
  user:any;
  quantity:number = 1;
  bid:number=0;
  routeId:number = 0;
  id:number = 1;
  scrolledLast:string = "";
  canceled: boolean = false;
  wallet:string='';
  purchaseConfirm='';
  constructor(private service: ApiService, private mainfacade:MainFacade,private sanitizer: DomSanitizer, private toastService: ToastService, 
    private router:Router, private activeRoute:ActivatedRoute, private navfacade:NavFacade) { 
    //const btn = document.querySelector("button.mobile-menu-button");
    //const menu = document.querySelector(".mobile-menu");
    //const menulink = document.querySelector(".menu-link");
    // Add Event Listeners

    activeRoute.queryParams.subscribe(
      (params) => { 
        this.routeId = params['id'];
        let temp = params['project'];
       
       
        
      });

  }

  ngOnInit() {
    //this.service.setToken();
    this.createSubscriptions();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  safeSVGRet(svgContent:any){
    return this.sanitizer.bypassSecurityTrustHtml(svgContent);
  }

  
  createSubscriptions(){
    this.navfacade.getPopup(null);
    this.user$ = this.mainfacade.user$;
    this.purchase$ = this.mainfacade.purchase$;
    this.bid$ = this.mainfacade.bid$;
    this.selectListing$ = this.mainfacade.selectListing$;
    this.myLikes$ = this.mainfacade.myLikes$;
    this.project$ = this.mainfacade.project$;
    this.dark$ = this.mainfacade.dark$;
    this.gen1$ = this.mainfacade.gen1$;
    this.gen2$ = this.mainfacade.gen2$;
    this.jiraverse$ = this.mainfacade.jiraverse$;
    this.dwc$ = this.mainfacade.dwc$;
    this.clj$ = this.mainfacade.clj$;
    this.projectLis$ = this.mainfacade.projectLis$;
    this.nightMode$ = this.mainfacade.nightMode$;
    this.user$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){      
        this.user = message;
      }

    });

    

    

    this.projectLis$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.projectLis = message;
      }

    });

    
    this.selectListing$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.selectListing = message;

        let seconds = 30;
  
        if(message.Type === 'Auction' && message.TypeName === 'Standard'){
          seconds = 5;
        }
        else if(message.Type === 'Auction' && message.TypeName === 'Dutch'){
          this.calcDADec(message)
        }

        if(this.selectListing && this.selectListing.Active){
          this.selectListingLoop(message.Id, seconds);
        }

        
      }

    });

    
    this.myLikes$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.myLikes = message;
      }

    });

    this.project$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        if(message === 'all'){
          this.id = 4;
        }
        else if(message === 'godjira'){
          this.id = 1;
        }
        else if(message === 'dwc'){
          this.id = 2;
        }
        else if(message === 'darkechelon'){
          this.id = 3;
        }
      }

    });

    
  }

  calcDADec(listing){
    var d = new Date();
    var myTimezone = "America/Toronto";
    var myDatetimeFormat= "YYYY-MM-DD hh:mm:ss";
    var myDatetimeString = moment(d).utc().format(myDatetimeFormat);/// tz(myTimezone).format(myDatetimeFormat);
    var dP = new Date(listing.AuctionStart);
    var dPE = new Date(listing.AuctionEnd);
    var myDatetimeStringPost = moment.utc(dP).format(myDatetimeFormat);
   
    var duration = moment.duration(moment().diff(moment(moment.utc(dP))));
    var mins = duration.asSeconds();
    
    //seconds until end
    //console.log(mins)
    //let time = ((listing.Price - listing.CurrentPrice)/listing.DAPriceDec)*Number(listing.DATimeInc);
    //Seconds passed
    let timesInc = (mins)/(Number(listing.DATimeInc)*60);
    let timesIncRd = Math.floor((mins)/(Number(listing.DATimeInc)*60));
    this.daCountDown = Math.abs(((timesIncRd+1)*(Number(listing.DATimeInc)*60))-(timesInc*(Number(listing.DATimeInc)*60))); 

  }


  async selectListingLoop(listingId, seconds){
    await this.startTimer(seconds);
    if(!this.canceled && this.selectListing && this.selectListing.Id === listingId){
      this.mainfacade.selectListing(this.selectListing);
    }
    else {
      this.canceled = false;
      this.selectListing = null;
    }
    
  }

  async startTimer(seconds:number){
    let mili = seconds * 1000;
    if(mili > 0){
      await this.delay(mili);
    }
    
  }

  closeListing(){
    this.canceled = true;
    this.mainfacade.selectListing(null);
    
  }

  delay(t) {
    return new Promise(resolve => setTimeout(resolve, t))
  }
  
  getTime(date:any){
    if(!date){
      return '0';
    }
    var d = new Date();
    var dP = new Date(date);
    var myDatetimeFormat= "YYYY-MM-DD hh:mm:ss A";
    var myDatetimeString = moment.utc(d).format(myDatetimeFormat);/// tz(myTimezone).format(myDatetimeFormat);
    var myDatetimeStringPost = moment.utc(dP).format(myDatetimeFormat);
    let duration = moment.duration(moment(myDatetimeStringPost).diff(moment(myDatetimeString)));

    if(moment(myDatetimeString).isSameOrAfter(moment(myDatetimeStringPost))){
      return 0;
    }
    let seconds = duration.asSeconds();
    
    return !seconds || seconds > 0 ? seconds  : '0';
  }

  getDays(date:any){
    if(!date){
      return '0';
    }
    var d = new Date();
    var dP = new Date(date);
    var myDatetimeFormat= "YYYY-MM-DD hh:mm:ss A";
    var myDatetimeString = moment.utc(d).format(myDatetimeFormat);/// tz(myTimezone).format(myDatetimeFormat);
    var myDatetimeStringPost = moment.utc(dP).format(myDatetimeFormat);
    let duration = moment.duration(moment(myDatetimeStringPost).diff(moment(myDatetimeString)));
   
    if(moment(myDatetimeString).isSameOrAfter(moment(myDatetimeStringPost))){
      return 0;
    }
    let seconds = duration.asSeconds();
 
    return !seconds || seconds > 0 ? Math.floor(seconds/60/60/24)  : 0;
  }

  purchaseBuyNow(){
    this.purchaseConfirm='';
    if(this.wallet === ''){
      this.toastService.error("Please set a wallet.");
      return;
    }
    if(Number(this.user.TokenBalance) < this.selectListing.Price){
      this.toastService.error("You don't have enough JIRA.");
      return;
    }

    /*if(!confirm('Please confirm your purchase of ' + this.selectListing.ListingName)){
      return;
    }*/
    
  
    //check own from nft
    this.mainfacade.makePurchase(this.user.Id, this.selectListing.Id, this.wallet, this.quantity, this.wallet, this.user.DiscordId);
  }

  purchaseBlind(){
    this.purchaseConfirm='';
    if(this.wallet === ''){
      this.toastService.error("Please set a wallet.");
      return;
    }
    if(Number(this.user.TokenBalance) < this.bid ){
      this.toastService.error("You don't have enough JIRA.")
      return;
    }
    
    if(Number(this.bid) < this.selectListing.CurrentPrice){
      this.toastService.error("You must bid atleast the current floor price.")
      return;
    }


    //check own from nft

    this.mainfacade.placeBid(this.user.Id, this.selectListing.Id, this.bid, this.wallet);
  }

  purchaseDutch(){
    this.purchaseConfirm='';
    if(this.wallet === ''){
      this.toastService.error("Please set a wallet.");
      return;
    }
    if(Number(this.user.TokenBalance) < this.selectListing.CurrentPrice){
      this.toastService.error("You don't have enough JIRA.");
      return;
    }


    //check own from nft

    this.mainfacade.makePurchase(this.user.Id, this.selectListing.Id, this.wallet, this.quantity, this.wallet, this.user.DiscordId);
  }

  makeBid(){
    this.purchaseConfirm='';
    if(this.wallet === ''){
      this.toastService.error("Please set a wallet.");
      return;
    }
    /*if(Number(this.user.TokenBalance) < this.bid){
      this.toastService.error("You don't have enough JIRA.")
      return;
    }*/

    if(Number(this.bid) < this.selectListing.CurrentPrice){
      this.toastService.error("You must bid atleast the current floor price.")
      return;
    }

    /*if(!confirm('Please confirm your purchase of ' + this.selectListing.ListingName)){
      return;
    }*/

    //check own from nft

    this.mainfacade.placeBid(this.user.Id, this.selectListing.Id, this.bid, this.wallet);
  }

  quantityChange(change){
    if(change === 'add'){
      this.quantity += 1;
    }
    else{
      if(this.quantity > 1){
        this.quantity -= 1;
      }
    }
  }

  getPrice(price){
    return Number(price) * this.quantity;
  }

  like(listingId, type){
    this.mainfacade.likesListing(this.user.Id, listingId,type);
  }

  liked(listingId){
    return this.myLikes.find((x)=>{
      return listingId === x.Id;
    })
  }

}
