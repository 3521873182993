import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NavFacade } from 'src/app/nav/nav.facade';
var moment = require('moment-timezone');
@Component({
  selector: 'app-balancehistory',
  templateUrl: './balancehistory.component.html',
  styleUrls: ['./balancehistory.component.scss']
})
export class BalancehistoryComponent implements OnInit {
  
  public burn$: Observable<any>|undefined;
  
  constructor(
  private navFacade:NavFacade) {
    

  }

  ngOnInit(): void {
    
    this.setObservables();
  }

  

  setObservables() {
    this.burn$ = this.navFacade.burn$;
    

  }

  formatDate(date){
    var myDatetimeFormat= 'llll';
    var myDatetimeString = moment(date).format(myDatetimeFormat);
    return myDatetimeString
  }


}
