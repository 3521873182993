import { Injectable  } from '@angular/core';
import { Component } from '@angular/core';
import Moralis from 'moralis';
import Web3Modal from 'web3modal';
import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';
import { Router } from '@angular/router';
import { abi } from '../../contracts/prod/jira';
import { purabi } from '../../contracts/prod/purjira';
import { stabi } from '../../contracts/prod/stake';
import { ToastService } from 'angular-toastify';
import { checkAddressChecksum } from 'web3-utils';
//import {environment} from '../../../environments/environment.dev';
@Injectable({
  providedIn: 'root'
})
export class Web3Service {
  provider:any;
  web3:any;
  web3Modal:any;
  wallet:any;
  constructor(private router:Router, private toast:ToastService) {
   
  }

  async initWeb3(){

    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: "68bbfa6dd6594f328012419c5b654b2f" // required
        }
      }
    };
    
    this.web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });

    try {
      this.provider = await this.web3Modal.connect();
      this.web3  = new Web3(this.provider);
      let network = await this.web3.eth.net.getNetworkType();
   

      //Display warning if on the wrong networkmain1
      if(network !== 'main'){
        this.toast.info("Please switch to the Ethereum Mainnet network.");
        await this.web3.currentProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex('1') }]
        });
        network = await this.web3.eth.net.getNetworkType();
      
        //return;
      }

      let accounts = await this.web3.eth.getAccounts();
      
      let walletSet = accounts[0];
      this.wallet = accounts[0];
      if(!localStorage.getItem('sig')){
        let message = 'This is used to verify that you own this account for JiraXchange and secures that any actions on the site are made by you alone.';
        let msg = `0x${Buffer.from(message, 'utf8').toString('hex')}`;
        let sig = await this.web3.currentProvider.request({
          method: 'personal_sign',
          params: [msg, walletSet, environment.token]
        });
   
        //console.log(sig)
        localStorage.setItem('sig', sig);
        //let rec = await this.web3.eth.accounts.recover(walletSet,sig);
        //console.log(rec)
      }
      else {
        let message = 'This is used to verify that you own this account for JiraXchange and secures that any actions on the site are made by you alone.';
        let msg = `0x${Buffer.from(message, 'utf8').toString('hex')}`;
        let rec = await this.web3.eth.accounts.recover(msg,localStorage.getItem('sig'));
        if(rec.toLowerCase() !== walletSet.toLowerCase()){
          let sig = await this.web3.currentProvider.request({
            method: 'personal_sign',
            params: [msg, walletSet, environment.token]
          });
          
          localStorage.setItem('sig', sig);
        }
      }

     
      return {walletSet:walletSet};
    }
    catch(err){
      console.log(err)
      return {error:true,message:err.message};
    }
  }

  async logout(){
    try {
      this.web3Modal.clearCachedProvider();
      localStorage.removeItem('code');
      return {};
    }
    catch(err){
      return {error:true,message:err.message};
    }
  }

  async getJiraBalance(addresses){
    try {
      let contract = new this.web3.eth.Contract(abi, environment.jira);
      let address = [];
      addresses.forEach((wall)=>{
        let found = address.find((fwal)=>{
          return fwal === wall;
        });
        if(!found){
          address.push(wall);
        }
      })
      let wallets = [];
      let tokens = 0;
      for(let x = 0; address.length > x; x++){
        let jbal = await contract.methods.balanceOf(address[x]).call();
        let balance = this.web3.utils.fromWei(jbal);
        let num = Number(balance);
        let newNew = {
          Address: address[x],
          Amount:Math.floor(num)
        }
        tokens += Math.floor(num);
        wallets.push(newNew);
      }
      return {tokens:tokens, perWallet:wallets};
    }
    catch(err){
  
      return {error:true,message:err.message};
    }
  }

  async getNFTsWallet(wallets){
    try{
      let ethNFTs = [];
      let address = [];
      wallets.forEach((wall)=>{
        let found = address.find((fwal)=>{
          return fwal === wall;
        });
        if(!found){
          address.push(wall);
        }
      });
      
      let contract = new this.web3.eth.Contract(stabi, environment.stjira);
      for(let wal = 0; wal < address.length; wal++){
        let data = await this.getMoralisData(address[wal], null) as any;
        ethNFTs = ethNFTs.concat(data.result);
        let count1 = 0;
        while(data.cursor){
          data = await this.getMoralisData(address[wal], data.cursor) as any;
          ethNFTs = ethNFTs.concat(data.result);
          count1++;
        }
      }

      
      let nfts=[];
      let dwc = false;
      let dark = false;
      let gen1 = false;
      let gen2 = false;
      let jiraverse = false;
      let fused= false;
      let jiraland = false;
      let clj = false;
      let spit = false;
      //group by contract
      for(let nft of ethNFTs){
        //Gen1
        if(nft.token_address.toString().toLowerCase() === ('0x9ada21A8bc6c33B49a089CFC1c24545d2a27cD81').toString().toLowerCase()){
          gen1 = true;
          let nftObj = {
            Project:'Godjira Gen 1',
            Image:'https://godjira-gen-images.s3.amazonaws.com/'+nft.token_id+'.gif',
            token_id:nft.token_id,
            token_address:nft.token_address,
            Name:'PG: Genesis #'+nft.token_id,
            Format:'image'
          }
          nfts.push(nftObj)
        }
        //Gen2
        else if(nft.token_address.toString().toLowerCase() === ('0xEDc3AD89f7b0963fe23D714B34185713706B815b').toString().toLowerCase()){
          gen2 = true;
          let nftObj = {
            Project:'Godjira Gen 2',
            Image:'https://godjira-gen2-images.s3.amazonaws.com/'+nft.token_id+'.gif',
            token_id:nft.token_id,
            token_address:nft.token_address,
            Name:'PG: Gen 2 #'+nft.token_id,
            Format:'image'
          }
          nfts.push(nftObj)
        }
        //Fusion
        else if(nft.token_address.toString().toLowerCase() === ('0x4aBd6aF27422E63a279Ac2b7c45A67C753144C10').toString().toLowerCase()){
          let meta = await this.getFusion('https://57lqpw8ex1.execute-api.us-west-2.amazonaws.com/Prod/metadata/'+nft.token_id)
          gen2 = true;
          let nftObj = {
            Project:'Godjira Fusion',
            Image:meta?.image,
            token_id:nft.token_id,
            token_address:nft.token_address,
            Name:'PG: Fusion #'+nft.token_id,
            Format:'image'
          }
          nfts.push(nftObj)
        }
        //Jiraverse
        else if(nft.token_address.toString().toLowerCase() === ('0xbd3f82a81c3f74542736765ce4fd579d177b6bc5').toString().toLowerCase()){
          let meta = await this.getFusion('https://jiraverseapi.xyz/api/wl/Meta/'+nft.token_id);
          console.log(meta)
          jiraverse = true;
          let nftObj = {
            Project:'Jiraverse',
            Image:meta?.image,
            token_id:nft.token_id,
            token_address:nft.token_address,
            Name:'Jiraverse #'+nft.token_id,
            Format:'image'
          }
          nfts.push(nftObj)
        }
        //Jiravox
        else if(nft.token_address.toString().toLowerCase() === ('0x81670ff57df2c070bd4a5643e8c19ad09ac5c57b').toString().toLowerCase()){
          jiraverse = true;
          let token = nft.token_id;
          let vxid;
          if(token>=10000){
            vxid = token;
          }
          else if( token<10000 && token>=1000){
            vxid = '0' +token;
          }
          else if(token<1000 && token>=334){
            vxid = '00' + token;
          }
          else if(token<334 && token>=100){
            vxid = '0' + token;
          }
          else if(token<100 && token>=10){
            vxid = '00' + token;
          }
          else {
            vxid = '000' + token;
          }
          let nftObj = {
            Project:'Jiravox',
            Image:'https://godjira-voxel.nyc3.digitaloceanspaces.com/JIRA'+vxid+'.png',
            token_id:nft.token_id,
            token_address:nft.token_address,
            Name:'PG: JIRAVOX #'+nft.token_id,
            Format:'image'
          }
          nfts.push(nftObj)
        }
        else if(nft.token_address.toString().toLowerCase() === ('0xe71a8ddCad4a950a1bbea58a301F62B5337eCB5c').toString().toLowerCase()){
          let bagData = await contract.methods.bags(nft.token_id).call();
          if(bagData.genTokenId > 0){
            gen1 = true;
            let nftObj = {
              Project:'Godjira Gen 1',
              Image:'https://godjira-gen-images.s3.amazonaws.com/'+bagData.genTokenId+'.gif',
              token_id:bagData.genTokenId,
              token_address:nft.token_address,
              Name:'PG: Genesis #'+bagData.genTokenId,
              Format:'image'
            }
            nfts.push(nftObj)
          }

          let countOfGen2 = await contract.methods.getTotalGen2InBag(nft.token_id).call();
          if(countOfGen2 > 0){
            let track = countOfGen2;
            while(track>0){         
              gen2 = true;
              let gen2Tok = await contract.methods.getGen2InBagByIndex(nft.token_id, track - 1).call();
              let nftObj = {
                Project:'Godjira Gen 2',
                Image:'https://godjira-gen2-images.s3.amazonaws.com/'+gen2Tok+'.gif',
                token_id:gen2Tok,
                token_address:nft.token_address,
                Name:'PG: Gen 2 #'+gen2Tok,
                Format:'image'
              }
              nfts.push(nftObj)
              track--;
            }
          }
          
        }
        //DWC
        else if(nft.token_address.toString().toLowerCase() === ('0xb7AD02B2851248BF7bf257bfeb003080105ac4ab').toString().toLowerCase()){
          dwc = true;
          let nftObj = {
            Project:'DWC Diamond Pass',
            Image:'https://godjiramarketplace.nyc3.digitaloceanspaces.com/DWC-vid.mp4',
            token_id:nft.token_id,
            token_address:nft.token_address,
            Name:'DWC Diamond Pass #'+nft.token_id,
            Format:'video'
          }
          nfts.push(nftObj)
        }
        //DWC
        else if(nft.token_address.toString().toLowerCase() === ('0x2Ca9aD9be2e9745c320125649b0EFc7dAd92CB33').toString().toLowerCase()){
          dwc = true;
          let nftObj = {
            Project:'DWC Carbon Pass',
            Image:'https://godjiramarketplace.nyc3.digitaloceanspaces.com/DWC-Carbon.mp4',
            token_id:nft.token_id,
            token_address:nft.token_address,
            Name:'DWC Carbon Pass #'+nft.token_id,
            Format:'video'
          }
          nfts.push(nftObj)
        }
        //Dark Echelon Gold
        /*else if(nft.token_address.toString().toLowerCase() === ('0x6fc3AD6177B07227647aD6b4Ae03cc476541A2a0').toString().toLowerCase()){
          let data = await this.getImage(nft.token_uri);
      
          dark = true;
          let nftObj = {
            Project:'Dark Echelon Gold',
            Image:data.image,
            token_id:nft.token_id,
            token_address:nft.token_address
          }
          nfts.push(nftObj)
        }*/
        //Spit Buddies
        /*else if(nft.token_address.toString().toLowerCase() === ('0x2Ca9aD9be2e9745c320125649b0EFc7dAd92CB33').toString().toLowerCase()){
          spit = true;
          let nftObj = {
            Project:'DWC Carbon Pass',
            Image:'https://godjiramarketplace.nyc3.digitaloceanspaces.com/DWC-Carbon.mp4',
            token_id:nft.token_id,
            token_address:nft.token_address,
            Name:'DWC Carbon Pass #'+nft.token_id,
            Format:'image'
          }
          nfts.push(nftObj)
        }*/
        //CLJ
        else if(nft.token_address.toString().toLowerCase() === ('0x867ba3C89fB7C8F4d72068859c26d147F5330043').toString().toLowerCase()){
          clj = true;
          let nftObj = {
            Project:'Chubby Little Jiras',
            Image:'https://chubbylittlejiras.io/metadata/images/'+nft.token_id+'.png',
            token_id:nft.token_id,
            token_address:nft.token_address,
            Name:'Little Jira #'+nft.token_id,
            Format:'image'
          }
          nfts.push(nftObj)
        }

        
      
      };
      let showNfts = [];
      nfts.forEach((nf)=>{
        let found = showNfts.find((sh)=>{
          return sh.Project === nf.Project;
        })

        if(found){
          if(found.NFTS.length <= 3){
            found.NFTS.push({
              Image:nf.Image,
              Name:nf.Name,
              Format:nf.Format
            })
          }
          found.Count++;
        }
        else{
          let nt = {
            Project:nf.Project,
            Count:1,
            NFTS:[]
            
          }
          nt.NFTS.push({
            Image:nf.Image,
            Name:nf.Name,
            Format:nf.Format
          })
          showNfts.push(nt)
        }
      })

      return { nfts:nfts, dwc:dwc, dark:dark, gen1:gen1, gen2:gen2, jiraverse:jiraverse, clj:clj, showNfts:showNfts,pg:gen1 || gen2 || jiraverse, spit:spit, };

    }
    catch(err){

      return {error:true,message:err.message};

    }
  }

  async getMoralisData(token_address, cursor){
    try{
      let url = 'https://deep-index.moralis.io/api/v2/'+token_address+'/nft';
      if(cursor){
        url += '?cursor='+cursor;
      }
      let response = await fetch(url, {
        headers: new Headers({'x-api-key': environment.moralis})
      });
      let data = null;

      if(response) {
        data = await response.json();

      }
      return data;
    }
    catch(err){

    }
  }

  async getMoralisTokenData(token_address){
    try{
      let url = 'https://deep-index.moralis.io/api/v2/'+token_address+'/balance?chain=eth';
      
      let response = await fetch(url, {
        headers: new Headers({'x-api-key': environment.moralis})
      });
      let data = null;

      if(response) {
        data = await response.json();

      }
      return data;
    }
    catch(err){

    }
  }

  async burnJira(amount, address){
    try {
      return;
      //let accounts = await this.web3.eth.getAccounts();
      //let address = accounts[0];
      let contract = new this.web3.eth.Contract(abi, environment.jira);
      //Get balance and compare
      let formatedBurn = this.web3.utils.toWei(amount.toString());
      let gas = await contract.methods.transfer(environment.burn, formatedBurn).estimateGas({from: address});
    
      let limit = gas + 15000;
      let gasPrice = await this.web3.eth.getGasPrice();
      
      let transfer = await contract.methods.transfer(environment.burn, formatedBurn).send({from: address, gasLimit:limit, gasPrice:gasPrice})
      .on("transactionHash", async (hash) => {
        
        this.toast.info("Transaction has been started. Please wait for the transaction to be confirmed.");
      })
      .on("receipt", async(reciept) => {
        this.toast.success("Transaction has completed. Thank you for your purchase");
       
      })
      .on("error", async(error)=>{
        this.toast.error(error.message);
   
      });

      return;
    }
    catch(err){
   
      this.toast.error(err.message);
      return {error:true,message:err.message};
    }
  }

  async getImage(uri){
    try{
      let url = uri.replace('ipfs://','https://ipfs.io/ipfs/');
      
      let response = await fetch(url);
      let data = null;

      if(response) {
        data = await response.json();
      
      }
      return data;
    }
    catch(err){
      console.log(err)
    }
  }

  async getFusion(url){
    try{
     
      let response = await fetch(url);
      let data = null;

      if(response) {
        data = await response.json();
      
      }
      return data;
    }
    catch(err){
      console.log(err)
    }
  }

  async estimateJiraPurchase(amount){
    try {
      let contract = new this.web3.eth.Contract(purabi, environment.purjira);
      let formatedEth = this.web3.utils.toWei(amount.toString());
      let gas = await contract.methods.deposit(environment.burn).estimateGas({from: this.wallet, value: formatedEth});
      let gasFormat = this.web3.utils.fromWei(gas.toString(),'gwei');
      return Number(gasFormat);
    }
    catch(err){
      this.toast.error(err.message);
      return {error:true,message:err.message};
    }
  }

  async purchaseJira(amount){
    try {
      let contract = new this.web3.eth.Contract(purabi, environment.purjira);
      let formatedEth = this.web3.utils.toWei(amount.toString());
      let gas = await contract.methods.deposit(environment.burn).estimateGas({from: this.wallet, value: formatedEth});

      let limit = gas + 15000;
      let gasPrice = await this.web3.eth.getGasPrice();
      console.log(this.wallet)
      let transfer = await contract.methods.deposit(environment.burn).send({from: this.wallet, value: formatedEth, gasLimit:limit, gasPrice:gasPrice})
      .on("transactionHash", async (hash) => {
        
        this.toast.info("Transaction has been started. Please wait for the transaction to be confirmed.");
      })
      .on("receipt", async(reciept) => {
        this.toast.success("Transaction has completed. Thank you for your purchase");
       
      })
      .on("error", async(error)=>{
        this.toast.error(error.message);
   
      });
      await this.delay(3000);
     

      return {wallet:this.wallet};

    }
    catch(err){
      console.log(err)
      this.toast.error(err.message);
      return {error:true,message:err.message};
    }
  }

  delay(t) {
    return new Promise(resolve => setTimeout(resolve, t))
  }


}
