import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NavFacade } from 'src/app/nav/nav.facade';

@Component({
  selector: 'app-gamedashboard',
  templateUrl: './gamedashboard.component.html',
  styleUrls: ['./gamedashboard.component.scss']
})

export class GamedashboardComponent implements OnInit {
  
  public project$: Observable<any>|undefined;
  public nightMode$: Observable<any>|undefined;
  constructor(private router:Router, private navFacade:NavFacade) { }

  ngOnInit(): void {
    //gameUtil.gameRunUtil();
    //engineUtil.engineRunUtil();
    //gameUtil.gameRunUtil();
    
    this.project$ = this.navFacade.project$;
    this.nightMode$ = this.navFacade.nightMode$;
  }

  route(path ){
    this.router.navigate([path]);
  }

}
