import { Component, OnInit } from '@angular/core';
import { ToastService } from 'angular-toastify';
import { Observable } from 'rxjs';
import { NavFacade } from 'src/app/nav/nav.facade';
var moment = require('moment-timezone');
@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.scss']
})
export class PurchasesComponent implements OnInit {
  public error$: Observable<any>|undefined;
  public user$: Observable<any>|undefined;
  public history$: Observable<any>|undefined;
  purchaseToChange:any;
  showWallet:boolean = false;
  selectedWallet:string='';
  constructor(private navFacade:NavFacade,  private toast:ToastService) { }

  ngOnInit(): void {
    this.setObservables();
  }

  setObservables() {

    this.error$ = this.navFacade.error$;
    this.user$ = this.navFacade.user$;
    this.history$ = this.navFacade.history$;
  }

  showWalletModal(purchase){
    if(purchase.StopWalChange === 1){
      return;
    }
    this.purchaseToChange = purchase;
    this.showWallet = true;
  }

  submitWalletChange(){
    this.showWallet = false;
    this.navFacade.changeWallet(this.purchaseToChange.Id, this.purchaseToChange.UserId, this.selectedWallet);
    this.purchaseToChange = null;
    this.showWallet = false;

  }

  formatDate(date){
    var myDatetimeFormat= 'llll';
    var myDatetimeString = moment(date).format(myDatetimeFormat);
    return myDatetimeString
  }

}
