import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";
import { PagesModule } from "./pages/pages.module";
import { NavComponent } from './nav/nav.component';
import { environment } from "src/environments/environment.dev";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MarketEffects } from "./lib/+state/market.effects";
import { MarketFeatureKey, MarketInitialState, MarketReducer } from "./lib/+state/market.reducer";
import { NavFacade } from "./nav/nav.facade";
import { CountdownGlobalConfig, CountdownModule } from 'ngx-countdown';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ToastService, AngularToastifyModule } from 'angular-toastify';
import { CollectorsComponent } from './collectors/collectors.component';
import { SelectbarComponent } from './selectbar/selectbar.component';
import { RecentbuyComponent } from './recentbuy/recentbuy.component';
import { ListingsComponent } from './component/listings/listings.component';
import { SellComponent } from './component/sell/sell.component';
import { MarketactivityComponent } from './component/marketactivity/marketactivity.component';
import { TopsellersComponent } from './component/topsellers/topsellers.component';
import { AlienComponent } from './component/alien/alien.component';
import { CrappybirdComponent } from './component/crappybird/crappybird.component';
import { MarioComponent } from './component/mario/mario.component';
import { DuckhuntComponent } from './component/duckhunt/duckhunt.component';
import { WalletbarComponent } from './component/walletbar/walletbar.component';
import { FavoritesComponent } from './component/favorites/favorites.component';
import { BidsComponent } from './component/bids/bids.component';
import { BalancehistoryComponent } from './component/balancehistory/balancehistory.component';
import { WalletsComponent } from './component/wallets/wallets.component';
import { PurchasesComponent } from './component/purchases/purchases.component';
import { RafflesComponent } from './component/raffles/raffles.component';


@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    SidebarComponent,
    CollectorsComponent,
    SelectbarComponent,
    RecentbuyComponent,
    ListingsComponent,
    SellComponent,
    MarketactivityComponent,
    TopsellersComponent,
    AlienComponent,
    CrappybirdComponent,
    MarioComponent,
    DuckhuntComponent,
    WalletbarComponent,
    FavoritesComponent,
    BidsComponent,
    BalancehistoryComponent,
    WalletsComponent,
    PurchasesComponent,
    RafflesComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    PagesModule,
    ReactiveFormsModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    StoreModule.forFeature(MarketFeatureKey, MarketReducer, {
      initialState: MarketInitialState,
    }),
    EffectsModule.forFeature([MarketEffects]),
    StoreDevtoolsModule.instrument(),
    CountdownModule,
    AngularToastifyModule
    
  ],
  providers: [MarketEffects, NavFacade, ToastService],
  bootstrap: [AppComponent]
})
export class AppModule { }
