import { Component, OnInit } from '@angular/core';
import { ToastService } from 'angular-toastify';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavFacade } from 'src/app/nav/nav.facade';

@Component({
  selector: 'app-walletbar',
  templateUrl: './walletbar.component.html',
  styleUrls: ['./walletbar.component.scss']
})
export class WalletbarComponent implements OnInit {
  showPurchaseJirax:boolean = true;
  ethToJira:number=0;
  WalletDrop:boolean=false;
  jiraPurchaseConfirm:boolean=false;
  user:any;
  walletFull:any='';
  walletSplit:any='';
  public eth$: Observable<any>|undefined;
  public gas$: Observable<any>|undefined;
  public loading$: Observable<any>|undefined;
  public price$: Observable<any>|undefined;
  public login$: Observable<any>|undefined;
  public error$: Observable<any>|undefined;
  public user$: Observable<any>|undefined;
  public nightMode$: Observable<any>|undefined;
  private destroy$ = new Subject();
  constructor(private navFacade:NavFacade,private toast:ToastService) { }

  ngOnInit(): void {
    this.setObservables();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  setObservables() {
    this.gas$ = this.navFacade.gas$;
    this.login$ = this.navFacade.login$;
    this.error$ = this.navFacade.error$;
    this.user$ = this.navFacade.user$;
    this.eth$ = this.navFacade.eth$;
    this.price$ = this.navFacade.price$;
    this.loading$ = this.navFacade.loading$;
    this.nightMode$ = this.navFacade.nightMode$;
    this.loading$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 

      if(!message){
       this.showPurchaseJirax = false;
      }

    });

    this.user$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 

      if(message){
        this.user = message;
      
      }

    });

    this.login$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.walletFull = message
        this.walletSplit = message.slice(0, 4) + "..." + message.slice(41, 44);
      }

    });

    

  }
  
  calcEstimate(){
    this.navFacade.estJiraPurchase(this.ethToJira);
  }

  purchaseJira(){
    if(this.ethToJira <= 0){
      this.jiraPurchaseConfirm=false;
      this.toast.error('Value entered must be greater than 0.')
      return;
    }
    this.jiraPurchaseConfirm=false;
    this.navFacade.JiraPurchase(this.ethToJira);
  }

  closePopup(){
    this.navFacade.getPopup('');
  }

}
