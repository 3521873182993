import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MainFacade } from '../pages/main.facade';

@Component({
  selector: 'app-collectors',
  templateUrl: './collectors.component.html',
  styleUrls: ['./collectors.component.scss']
})
export class CollectorsComponent implements OnInit {
  public topSpenders$: Observable<any>|undefined;
  constructor(private mainfacade:MainFacade) { }

  ngOnInit(): void {
    this.createSubscriptions();
  }

  createSubscriptions(){
    this.topSpenders$ = this.mainfacade.topSpenders$;
    this.mainfacade.getTopSpenders();
  }


}
