import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MarketFeatureKey } from './market.reducer';

const getMarketFeatureKey = createFeatureSelector<any>(MarketFeatureKey);

export const getError = createSelector(
    getMarketFeatureKey,
(state: any) => {
  return state.error
}
);

export const getListings = createSelector(
  getMarketFeatureKey,
(state: any) => {
return state.listings
}
);

export const getUser = createSelector(
    getMarketFeatureKey,
(state: any) => {
  return state.user
}
);



export const login = createSelector(
    getMarketFeatureKey,
(state: any) => {
  return state.login
}
);

export const getDiscordData = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.discordData
}
);

export const getDiscordFail = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.discordError
}
);

export const getNfts = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.nfts
}
);

export const getNav = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.isNav
}
);

export const getTutorial = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.tutorial
}
);

export const getPurchase = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.purchase
}
);

export const getBids = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.bid
}
);

export const getProject = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.project
}
);

export const getHotListings = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.hot
}
);

export const getTopSpenders = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.topSpenders
}
);

export const getHistory = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.history
}
);

export const getBurn = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.burn
}
);

export const getTrans = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.trans
}
);

export const getSelectListing = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.selectListing
}
);

export const getPop = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.pop
}
);

export const getMyBids = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.mybids
}
);



export const getDark = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.dark
}
);

export const getDWC = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.dwc
}
);

export const getGen1 = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.gen1
}
);
export const getGen2 = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.gen2
}
);
export const getJiraverse = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.jiraverse
}
);

export const getRafflesEntered = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.rafflesEntered
}
);

export const getRaffleWinners = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.raffleWinners
}
);

export const getListingLikes = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.listingLikes
}
);

export const getMyLikes = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.myLikes
}
);

export const getTokens = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.tokens
}
);

export const getPerWallet = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.perWallet
}
);

export const getClaim = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.claim
}
);

export const getProjectLis = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.projectLis
}
);

export const getRecentBuy = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.recentBuy
}
);

export const getFilters = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.filters
}
);

export const getNight = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.nightMode
}
);
export const getShowNfts = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.showNfts
}
);
export const getCLJ = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.clj
}
);

export const getNotifications = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.notifications
}
);

export const getMySell = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.mySell
}
);

export const getSelectSell = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.selectSell
}
);

export const getAllSell = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.allSell
}
);

export const getActivity = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.allActivity
}
);

export const getMyActivity = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.myActivity
}
);

export const getTopSellers = createSelector(
  getMarketFeatureKey,
(state: any) => {
  console.log(state.topSellers)
  return state.topSellers
}
);

export const getSelectUserBuy = createSelector(
  getMarketFeatureKey,
(state: any) => {
  
  return state.selectUserBuy;
}
);

export const getUserList = createSelector(
  getMarketFeatureKey,
(state: any) => {
  
  return state.userList;
}
);

export const getUserProfile = createSelector(
  getMarketFeatureKey,
(state: any) => {
  
  return state.userProfile;
}
);

export const getEth = createSelector(
  getMarketFeatureKey,
(state: any) => {
  
  return state.eth;
}
);

export const getGas = createSelector(
  getMarketFeatureKey,
(state: any) => {
  
  return state.gas;
}
);

export const getLoading = createSelector(
  getMarketFeatureKey,
(state: any) => {
  
  return state.loading;
}
);

export const getPrice = createSelector(
  getMarketFeatureKey,
(state: any) => {
  
  return state.price;
}
);


export const getPG = createSelector(
  getMarketFeatureKey,
(state: any) => {
  
  return state.pg;
}
);

export const MarketQuery = {
    getMarketFeatureKey,
    getError,
    getUser,
    login,
    getListings,
    getDiscordData,
    getDiscordFail,
    getNfts,
    getNav,
    getTutorial,
    getPurchase,
    getBids,
    getProject,
    getTopSpenders,
    getHotListings,
    getHistory,
    getBurn,
    getTrans,
    getSelectListing,
    getPop,
    getMyBids,
    getGen1,
    getGen2,
    getJiraverse,
    getDark,
    getDWC,
    getRaffleWinners,
    getRafflesEntered,
    getListingLikes,
    getMyLikes,
    getTokens,
    getPerWallet,
    getClaim,
    getProjectLis,
    getRecentBuy,
    getFilters,
    getNight,
    getShowNfts,
    getCLJ,
    getNotifications,
    getMySell,
    getAllSell,
    getSelectSell,
    getActivity,
    getMyActivity,
    getTopSellers,
    getSelectUserBuy,
    getUserList,
    getUserProfile,
    getEth,
    getGas,
    getLoading,
    getPrice,
    getPG
};