import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { User } from '../lib/entities/User';
import { NavFacade } from '../nav/nav.facade';
import { MainFacade } from '../pages/main.facade';
import { takeUntil} from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  items:any[] = [
    {Name:"Explore", Icon:"../../../assets/img/nav-bar/compass.svg"},
    {Name:"Winners", Icon:"../../../assets/img/nav-bar/award.svg"},
    {Name:"Purchases", Icon:"../../../assets/img/nav-bar/shopping.svg"},
    {Name:"Wallet", Icon:"../../../assets/img/nav-bar/wallet.svg"},
    {Name:"Bids/Raffles", Icon:"../../../assets/img/nav-bar/auction.svg"},
    {Name:"Watchlist", Icon:"../../../assets/img/nav-bar/heart.svg"}
  ];
  public user$: Observable<any>|undefined;
  public project$: Observable<any>|undefined;
  public nightMode$: Observable<any>|undefined;
  private destroy$ = new Subject();
  night:boolean = false;
  user:User;
  locNav:string = 'dash';
  constructor(private mainfacade:MainFacade, private navfacade:NavFacade, private router:Router) {
    router.events.subscribe((val:any) => {
        if(val?.url?.indexOf("/market") >= 0){
          this.locNav = 'market';
        }
        else if(val?.url?.indexOf("/home") >= 0){
          this.locNav = 'dash';
        }
        else if(val?.url?.indexOf("/usermarket") >= 0){
          this.locNav = 'usermarket';
        }
        else if(val?.url?.indexOf("/gamedashboard") >= 0){
          this.locNav = 'gamedashboard';
        }
        else if(val?.url?.indexOf("/myprofile") >= 0){
          this.locNav = 'myprofile';
        }
      });

  }

  ngOnInit(): void {
   
    this.setObservables();

  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  setObservables() {
    this.project$ = this.navfacade.project$;
    this.user$ = this.navfacade.user$;
    this.nightMode$ = this.navfacade.nightMode$;
    this.user$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.user = message;
        //this.user.Wallet = this.user.Wallets[0];
      }

    });

    this.nightMode$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.night = message;
        //this.user.Wallet = this.user.Wallets[0];
      }

    });


  }

  updateNightMode(){
    this.navfacade.nightMode(this.night);
  }

  clearControls(){

  }

  controlModal(loc){

    if(loc === 'wallet'){

      this.navfacade.getPopup('wallet')
    }
    else if(loc === 'fav'){
      this.navfacade.getPopup('fav')
    }
    else if(loc === 'mypurchase'){
      this.navfacade.getPopup('mypurchase')

    }
    else if(loc === 'rafwin'){
      this.navfacade.getRaffleData(this.user.Id)
      this.navfacade.getPopup('rafwin');
    }
    else if(loc === 'bids'){

      this.navfacade.getPopup('bids')
    }
    else if(loc === 'collection'){

      this.navfacade.getPopup('collection')
    }
    else if(loc ==='myprofile'){
      this.router.navigate(['myprofile']);

    }
  }

  homeNav(){
    this.mainfacade.getRecentBuy(null);
    this.mainfacade.selectListing(null);
    this.router.navigate(['home']);
  }

  goToProfile(val){
    this.router.navigate(['/myprofile'], { queryParams: {list: val}});
  }
  
 

}
