import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import {MarketQuery} from '../lib/+state/market.selector';
import * as MarketActions from '../lib/+state/market.action';
import {MarketState} from '../lib/+state/market.reducer';

@Injectable({ providedIn: 'root' })
export class MainFacade {
    error$ = this.store.select(MarketQuery.getError);
    user$ = this.store.select(MarketQuery.getUser);
    nfts$ = this.store.select(MarketQuery.getNfts);
    listings$ = this.store.select(MarketQuery.getListings);
    tutorial$ = this.store.select(MarketQuery.getTutorial);
    purchase$ = this.store.select(MarketQuery.getPurchase);
    bid$= this.store.select(MarketQuery.getBids);
    topSpenders$ = this.store.select(MarketQuery.getTopSpenders);
    hot$ = this.store.select(MarketQuery.getHotListings);
    selectListing$ = this.store.select(MarketQuery.getSelectListing);
    mybids$ = this.store.select(MarketQuery.getMyBids);
    listingLikes$ = this.store.select(MarketQuery.getListingLikes);
    myLikes$ = this.store.select(MarketQuery.getMyLikes);
    project$ = this.store.select(MarketQuery.getProject);
    gen1$ = this.store.select(MarketQuery.getGen1);
    gen2$ = this.store.select(MarketQuery.getGen2);
    jiraverse$ = this.store.select(MarketQuery.getJiraverse);
    dark$ = this.store.select(MarketQuery.getDark);
    dwc$ = this.store.select(MarketQuery.getDWC);
    clj$ = this.store.select(MarketQuery.getCLJ);
    projectLis$ = this.store.select(MarketQuery.getProjectLis);
    recentBuy$ = this.store.select(MarketQuery.getRecentBuy);
    filters$ = this.store.select(MarketQuery.getFilters);
    nightMode$ = this.store.select(MarketQuery.getNight);
    showNfts$ = this.store.select(MarketQuery.getShowNfts);
    selectSell$ = this.store.select(MarketQuery.getSelectSell);
    mySell$ = this.store.select(MarketQuery.getMySell);
    allSell$ = this.store.select(MarketQuery.getAllSell);
    myActivity$ = this.store.select(MarketQuery.getMyActivity);
    allActivity$ = this.store.select(MarketQuery.getActivity);
    topSellers$ = this.store.select(MarketQuery.getTopSellers);
    selectUserBuy$ = this.store.select(MarketQuery.getSelectUserBuy);
    userList$ = this.store.select(MarketQuery.getUserList);
    userProfile$=this.store.select(MarketQuery.getUserProfile);
    pg$=this.store.select(MarketQuery.getPG);
    constructor(private store: Store<MarketState>) {}

    showNav(isNav:boolean){
        this.store.dispatch(MarketActions.setNav({data:isNav}))
    }

    getListings(id){
        this.store.dispatch(MarketActions.getListings({data:id}))
    }

    timedListing(showed, listings){
        this.store.dispatch(MarketActions.timedListing({showedListings:showed, listingIds:listings}));
    }

    getEndedListings(id){
        this.store.dispatch(MarketActions.getEndedListings({data:id}))
    }

    getHotListings(id){
        this.store.dispatch(MarketActions.getHotListings({data:id}))
    }

    makePurchase(userId:number, listingId:number, address:any, quantity:any, wallet:any, discordId:any){
        this.store.dispatch(MarketActions.purchase({userId:userId, listingId:listingId, address:address, quantity:quantity, wallet:wallet, discordId:discordId}));
    }

    placeBid(userId:number, listingId:number, amount:number, wallet:string){
        this.store.dispatch(MarketActions.placeBid({userId:userId,listingId:listingId,amount:amount, wallet:wallet}));
    }

    selectListing(listing:any){
        this.store.dispatch(MarketActions.selectListing({listing:listing}));
    }

    likesListing(userId:number, listingId:number, typeVal:string){
        this.store.dispatch(MarketActions.likeListing({userId:userId,listingId:listingId, typeVal:typeVal}));
    }

    getTopSpenders(){
        this.store.dispatch(MarketActions.getTopSpenders({data:null}));
    }

    getRecentBuy(proj){
        this.store.dispatch(MarketActions.getRecentBuy({data:proj}));
    }

    showFilters(filter){
        this.store.dispatch(MarketActions.showFilters({filters:filter}));
    }

    selectSell(sell){
        this.store.dispatch(MarketActions.selectSell({item:sell}));
    }

    selectUserBuy(buy){
        this.store.dispatch(MarketActions.selectUserBuy({buy:buy}));
    }

    mySell(userId){
        this.store.dispatch(MarketActions.mySell({userId:userId}));
    }

    allSell(type, search){
        this.store.dispatch(MarketActions.allSell({typeFilter:type, search:search}));
    }

    myActivity(userId){
        this.store.dispatch(MarketActions.myActivity({userId:userId}));
    }

    allActivity(){
        this.store.dispatch(MarketActions.allActivity({data:null}));
    }

    listSell(userId, listing){
        this.store.dispatch(MarketActions.listSell({userId:userId, listing:listing}));
    }

    cancelSell(userId, listingId){
        this.store.dispatch(MarketActions.cancelSell({userId:userId, listId:listingId}));
    }

    purchaseUserListing(userId, listingId, wallet){
        this.store.dispatch(MarketActions.purchaseUserListing({userId:userId, listingId:listingId, wallet:wallet}));
    }

    search(val){
        this.store.dispatch(MarketActions.userSearch({search:val}));
    }

    userProfile(userId){
        this.store.dispatch(MarketActions.getUserProfile({userId:userId}));
    }

    deleteWallet(userId, wallet, walletSet){
        this.store.dispatch(MarketActions.deleteWallet({userid:userId,wallet:wallet, walletSet:walletSet}));
    }
/*

    startTutorial(){
        this.store.dispatch(MarketActions.startTutorial({ data:null}));
    }*/

    
}