import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { Observable } from 'rxjs';
import { MainFacade } from '../main.facade';
var moment = require('moment-timezone');
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  public userProfile$: Observable<any>|undefined;
  userId=0
  select:any = 1;
  constructor(private mainFacade:MainFacade,private activeRoute:ActivatedRoute,  private toastr:ToastService) {
    activeRoute.queryParams.subscribe(
      (params) => { 

        if(this.userId !== params['user']){
          this.userId = params['user'];
          
          this.mainFacade.userProfile(this.userId);
        }
        else{
          this.userId = params['user'];
        }
        
        
      });

  }

  ngOnInit(): void {
    this.setObservables();
  }


  setObservables() {
    this.userProfile$ = this.mainFacade.userProfile$;
  }

  formatDate(date){
    var myDatetimeFormat= 'llll';
    var myDatetimeString = moment(date).format(myDatetimeFormat);
    return myDatetimeString
  }

  selectBuy(listing){
    console.log(listing)
    this.mainFacade.selectUserBuy(listing);
  }

}
