import { createAction, props } from '@ngrx/store';

//UNIVERSAL
export const loadingStart = createAction(
    '[Market] LOADING_STARTED',
    props<{ data:boolean }>()
);

export const setNav = createAction('[Market] SET_NAV',
  props<{data: any }>()
);

export const setNavSuccess = createAction('[Market] SET_NAV_SUCCESS',
  props<{isNav: any }>()
);

export const setLoading = createAction('[Market] SET_LOADING',
  props<{data: any }>()
);

export const setLoadingSuccess = createAction('[Market] SET_LOADING_SUCCESS',
  props<{loading: any }>()
);

export const getUserProfile = createAction('[Market] GET_USER_PROFILE',
  props<{userId: any }>()
);

export const getUserProfileFail = createAction('[Market] GET_USER_PROFILE_FAIL',
  props<{error: any }>()
);

export const getUserProfileSuccess = createAction('[Market] GET_USER_PROFILE_SUCCESS',
  props<{userProfile: any }>()
);

export const userSearch = createAction('[Market] USER_SEARCH',
  props<{search: any }>()
);

export const userSearchFail = createAction('[Market] USER_SEARCH_FAIL',
  props<{error: any }>()
);

export const userSearchSuccess = createAction('[Market] USER_SEARCH_SUCCESS',
  props<{userList: any[] }>()
);

export const getUser = createAction('[Market] GET_USER',
  props<{address: any }>()
);

export const getUserFail = createAction('[Market] GET_USER_FAIL',
  props<{error: any }>()
);

export const getUserSuccess = createAction('[Market] GET_USER_SUCCESS',
  props<{user: any, history:any, burn:any, trans:any, bids:any, myLikes:any, claim:any, notifications:any, userListings:any[] }>()
);

export const getUserETH = createAction('[Market] GET_USER_ETH',
  props<{address: any }>()
);

export const getUserETHFail = createAction('[Market] GET_USER_ETH_FAIL',
  props<{error: any }>()
);

export const getUserETHSuccess = createAction('[Market] GET_USER_ETH_SUCCESS',
  props<{eth: any}>()
);

export const getTokens = createAction('[Market] GET_TOKENS',
  props<{address: any }>()
);

export const getTokensFail = createAction('[Market] GET_TOKENS_FAIL',
  props<{error: any }>()
);

export const getTokensSuccess = createAction('[Market] GET_TOKENS_SUCCESS',
  props<{tokens:any, perWallet:any[] }>()
);

export const burnTokens = createAction('[Market] GET_BURN_TOKENS',
  props<{address: any, amount:any }>()
);

export const burnTokensFail = createAction('[Market] GET_BURN_TOKENS_FAIL',
  props<{error: any }>()
);

export const burnTokensSuccess = createAction('[Market] GET_BURN_TOKENS_SUCCESS',
  props<{success:any }>()
);

export const updateAvatar = createAction('[Market] UPDATE_AVATAR',
  props<{id: any, url:any, address:any }>()
);

export const updateAvatarFail = createAction('[Market] UPDATE_AVATAR_FAIL',
  props<{error: any }>()
);

export const updateAvatarSuccess = createAction('[Market] UPDATE_AVATAR_SUCCESS',
  props<{status: any }>()
);

export const getListings = createAction('[Market] GET_LISTINGS',
  props<{data: any }>()
);

export const getListingsFail = createAction('[Market] GET_LISTINGS_FAIL',
  props<{error: any }>()
);

export const getListingsSuccess = createAction('[Market] GET_LISTINGS_SUCCESS',
  props<{listings: any[] }>()
);

export const getEndedListings = createAction('[Market] GET_ENDED_LISTINGS',
  props<{data: any }>()
);

export const getEndedListingsFail = createAction('[Market] GET_ENDED_LISTINGS_FAIL',
  props<{error: any }>()
);

export const getEndedListingsSuccess = createAction('[Market] GET_ENDED_LISTINGS_SUCCESS',
  props<{listings: any[] }>()
);

export const login = createAction('[Market] GET_LOGIN',
  props<{provider: any }>()
);

export const loginFail = createAction('[Market] GET_LOGIN_FAIL',
  props<{error: any }>()
);

export const loginSuccess = createAction('[Market] GET_LOGIN_SUCCESS',
  props<{wallet: any }>()
);

export const logout = createAction('[Market] GET_LOGOUT',
  props<{data: any }>()
);

export const logoutFail = createAction('[Market] GET_LOGOUT_FAIL',
  props<{error: any }>()
);

export const logoutSuccess = createAction('[Market] GET_LOGOUT_SUCCESS',
  props<{wallet: any }>()
);

export const discordData = createAction(
    '[Market] DISCORD_DATA',
    props<{ code:string, wallet:string }>()
    );
  
  export const discordDataSuccess = createAction(
      '[Market] DISCORD_DATA_SUCCESS',
      props<{ Discord: any }>()
    );
  
  export const discordDataFail = createAction(
      '[Market] DISCORD_DATA_FAIL',
      props<{ error: Error }>()
  );

  export const getNfts = createAction(
    '[Market] GET_NFTS',
    props<{ data:any }>()
    );
  
  export const getNftsSuccess = createAction(
      '[Market] GET_NFTS_SUCCESS',
      props<{ Nfts: any }>()
    );
  
  export const getNftsFail = createAction(
      '[Market] GET_NFTS_FAIL',
      props<{ error: any }>()
  );

  export const purchase = createAction(
    '[Market] PURCHASE',
    props<{ userId:any, listingId:any, address:any, quantity:any, wallet:any, discordId:any }>()
    );
  
  export const purchaseSuccess = createAction(
      '[Market] PURCHASE_SUCCESS',
      props<{ purchase:any }>()
    );
  
  export const purchaseFail = createAction(
      '[Market] PURCHASE_FAIL',
      props<{ error: any }>()
  );

  export const placeBid = createAction(
    '[Market] PLACE_BID',
    props<{ userId:any, listingId:any, amount:any, wallet:any }>()
    );
  
  export const placeBidSuccess = createAction(
      '[Market] PLACE_BID_SUCCESS',
      props<{ bid:any }>()
    );
  
  export const placeBidFail = createAction(
      '[Market] PLACE_BID_FAIL',
      props<{ error: any }>()
  );

  export const changeProject = createAction(
    '[Market] CHANGE_PROJECT',
    props<{ project: any }>()
  );

  export const changeProjectFail = createAction(
    '[Market] CHANGE_PROJECT_FAIL',
    props<{ error: any }>()
  );

  export const changeProjectSuccess = createAction(
    '[Market] CHANGE_PROJECT_SUCCESS',
    props<{ project: any }>()
  );

  export const LoadProjects = createAction(
    '[Market] LOAD_PROJECT',
    props<{ data: any }>()
  );

  export const LoadProjectsFail = createAction(
    '[Market] LOAD_PROJECT_FAIL',
    props<{ error: any }>()
  );

  export const LoadProjectsSuccess = createAction(
    '[Market] LOAD_PROJECT_SUCCESS',
    props<{ projects: any[], price:number }>()
  );


  export const getHotListings = createAction('[Market] GET_HOT_LISTINGS',
  props<{data: any }>()
);

export const getHotListingsFail = createAction('[Market] GET_HOT_LISTINGS_FAIL',
  props<{error: any }>()
);

export const getHotListingsSuccess = createAction('[Market] GET_HOT_LISTINGS_SUCCESS',
  props<{listings: any[] }>()
);

export const getTopSpenders = createAction('[Market] GET_TOP_SPENDERS_LISTINGS',
props<{data: any }>()
);

export const getTopSpendersFail = createAction('[Market] GET_TOP_SPENDERS_LISTINGS_FAIL',
props<{error: any }>()
);

export const getTopSpendersSuccess = createAction('[Market] GET_TOP_SPENDERS_LISTINGS_SUCCESS',
props<{spenders: any[] }>()
);

export const selectUserBuy = createAction('[Market] SELECT_USER_BUY',
props<{buy:any }>()
);

export const selectUserBuySuccess = createAction('[Market] SELECT_USER_BUY_SUCCESS',
props<{buy: any}>()
);

export const selectListing = createAction('[Market] SELECT_LISTING',
props<{listing:any }>()
);

export const selectListingFail = createAction('[Market] SELECT_LISTING_FAIL',
props<{error: any }>()
);

export const selectListingSuccess = createAction('[Market] SELECT_LISTING_SUCCESS',
props<{listing: any, listingOG:any }>()
);

export const selectSell = createAction('[Market] SELECT_SELL',
props<{item:any }>()
);

export const selectSellSuccess = createAction('[Market] SELECT_SELL_SUCCESS',
props<{item: any}>()
);



export const listSell = createAction('[Market] LIST_SELL',
props<{userId:any, listing:any }>()
);

export const listSellFail = createAction('[Market] LIST_SELL_FAIL',
props<{error: any }>()
);

export const listSellSuccess = createAction('[Market] LIST_SELL_SUCCESS',
props<{items: any[]}>()
);

export const cancelSell = createAction('[Market] CANCEL_SELL',
props<{userId:any, listId:any }>()
);

export const cancelSellFail = createAction('[Market] CANCEL_SELL_FAIL',
props<{error: any }>()
);

export const cancelSellSuccess = createAction('[Market] CANCEL_SELL_SUCCESS',
props<{items: any[]}>()
);

export const mySell = createAction('[Market] MY_SELL',
props<{userId:any }>()
);

export const mySellFail = createAction('[Market] MY_SELL_FAIL',
props<{error: any }>()
);

export const mySellSuccess = createAction('[Market] MY_SELL_SUCCESS',
props<{items: any[]}>()
);

export const myActivity = createAction('[Market] MY_ACTIVITY',
props<{userId:any }>()
);

export const myActivityFail = createAction('[Market] MY_ACTIVITY_FAIL',
props<{error: any }>()
);

export const myActivitySuccess = createAction('[Market] MY_ACTIVITY_SUCCESS',
props<{activity: any[]}>()
);

export const allSell = createAction('[Market] ALL_SELL',
props<{typeFilter:any, search:any }>()
);

export const allSellFail = createAction('[Market] ALL_SELL_FAIL',
props<{error: any }>()
);

export const allSellSuccess = createAction('[Market] ALL_SELL_SUCCESS',
props<{items: any[], top:any[]}>()
);

export const allActivity = createAction('[Market] ALL_Activity',
props<{data:null }>()
);

export const allActivityFail = createAction('[Market] ALL_Activity_FAIL',
props<{error: any }>()
);

export const allActivitySuccess = createAction('[Market] ALL_Activity_SUCCESS',
props<{activity: any[]}>()
);

export const popup = createAction('[Market] POPUP',
props<{pop:any }>()
);

export const popupSuccess = createAction('[Market] POPUP_SUCCESS',
props<{pop: any[] }>()
);

export const getRaffleWin = createAction('[Market] GET_RAFFLE_WINS',
props<{userId:any }>()
);

export const getRaffleWinFail = createAction('[Market] GET_RAFFLE_WINS_FAIL',
props<{error: any }>()
);

export const getRaffleWinSuccess = createAction('[Market] GET_RAFFLE_WINS_SUCCESS',
props<{raffleWinners: any[], raffles:any[] }>()
);

export const likeListing = createAction('[Market] LIKE_LISTING',
props<{userId:any, listingId:any, typeVal:any }>()
);

export const likeListingFail = createAction('[Market] LIKE_LISTING_FAIL',
props<{error: any }>()
);

export const likeListingSuccess = createAction('[Market] LIKE_LISTING_SUCCESS',
props<{listing: any[] }>()
);

export const timedListing = createAction('[Market] TIMED_LISTING',
props<{showedListings:any, listingIds:any }>()
);

export const timedListingFail = createAction('[Market] TIMED_LISTING_FAIL',
props<{error: any }>()
);

export const timedListingSuccess = createAction('[Market] TIMED_LISTING_SUCCESS',
props<{listing: any[], old:any[] }>()
);

export const claimJira = createAction('[Market] CLAIM_JIRA',
props<{userIds:any }>()
);

export const claimJiraFail = createAction('[Market] CLAIM_JIRA_FAIL',
props<{error: any }>()
);

export const claimJiraSuccess = createAction('[Market] CLAIM_JIRA_SUCCESS',
props<{claim: any }>()
);

export const getRecentBuy = createAction('[Market] GET_RECENT_BUY',
props<{data: any }>()
);

export const getRecentBuyFail = createAction('[Market] GET_RECENT_BUY_FAIL',
props<{error: any }>()
);

export const getRecentBuySuccess = createAction('[Market] GET_RECENT_BUY_SUCCESS',
props<{recent: any[] }>()
);

export const showFilters = createAction('[Market] SHOW FILTERS',
props<{filters: boolean }>()
);

export const showFiltersSuccess = createAction('[Market] SHOW FILTERS_SUCCESS',
props<{filters: boolean }>()
);

export const changeWallet = createAction('[Market] CHANGE_WALLET',
props<{userid: any, pid:any, wallet:any }>()
);

export const changeWalletFail = createAction('[Market] CHANGE_WALLET_FAIL',
props<{error: any }>()
);

export const changeWalletSuccess = createAction('[Market] CHANGE_WALLET_SUCCESS',
props<{purchases: any[] }>()
);

export const deleteWallet = createAction('[Market] DELETE_WALLET',
props<{userid: any, wallet:any, walletSet:any }>()
);

export const deleteWalletFail = createAction('[Market] DELETE_WALLET_FAIL',
props<{error: any }>()
);

export const deleteWalletSuccess = createAction('[Market] DELETE_WALLET_SUCCESS',
props<{wallets: any[] }>()
);

export const night = createAction('[Market] NIGHT',
props<{night:boolean }>()
);

export const nightSuccess = createAction('[Market] NIGHT_SUCCESS',
props<{night: boolean }>()
);

export const purchaseUserListing = createAction('[Market] PURCHASE_USER_LISTINGS',
props<{userId: any, listingId:any, wallet:any }>()
);

export const purchaseUserListingFail = createAction('[Market] PURCHASE_USER_LISTINGS_FAIL',
props<{error: any }>()
);

export const purchaseUserListingSuccess = createAction('[Market] PURCHASE_USER_LISTINGS_SUCCESS',
props<{items: any[] }>()
);

export const estJiraPurchase = createAction('[Market] EST_JIRA_PURCHASE',
props<{amount:any }>()
);

export const estJiraPurchaseFail = createAction('[Market] EST_JIRA_PURCHASE_FAIL',
props<{error: any }>()
);

export const estJiraPurchaseSuccess = createAction('[Market] EST_JIRA_PURCHASE_SUCCESS',
props<{gas: any[] }>()
);

export const JiraPurchase = createAction('[Market] JIRA_PURCHASE',
props<{amount:any }>()
);

export const JiraPurchaseFail = createAction('[Market] JIRA_PURCHASE_FAIL',
props<{error: any }>()
);

export const JiraPurchaseSuccess = createAction('[Market] JIRA_PURCHASE_SUCCESS',
props<{eth: any[] }>()
);



  

  
