import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { MainComponent } from "./pages/main/main.component";
import { AuthGuard } from "./auth.guard";
import { ProfileComponent } from "./pages/profile/profile.component";
import { HomeComponent } from "./pages/home/home.component";
import { UsermarketComponent } from "./pages/usermarket/usermarket.component";
import { GamedashboardComponent } from "./pages/gamedashboard/gamedashboard.component";
import { AlienComponent } from "./component/alien/alien.component";
import { UserComponent } from "./pages/user/user.component";
import { CrappybirdComponent } from "./component/crappybird/crappybird.component";
import { MarioComponent } from "./component/mario/mario.component";
import { DuckhuntComponent } from "./component/duckhunt/duckhunt.component";
import { HiddenroomComponent } from "./pages/hiddenroom/hiddenroom.component";


const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: HomeComponent },
  { path: "market", component: MainComponent },
  { path: "myprofile", component: ProfileComponent },
  { path:"gamedashboard", component:GamedashboardComponent},
  {path:'userprofile',component:UserComponent},
  {path:'crappybird',component:CrappybirdComponent},
  {path:'mario',component:MarioComponent},
  {path:'duckhunt',component:DuckhuntComponent},
  {path:'hidden', component:HiddenroomComponent}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: []
})
export class AppRoutingModule { }
//{ path: "market", component: MainComponent },