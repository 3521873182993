import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastService } from 'angular-toastify';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MarketState } from 'src/app/lib/+state/market.reducer';
import { NavFacade } from 'src/app/nav/nav.facade';
import { MainFacade } from 'src/app/pages/main.facade';

@Component({
  selector: 'app-marketactivity',
  templateUrl: './marketactivity.component.html',
  styleUrls: ['./marketactivity.component.scss']
})
export class MarketactivityComponent implements OnInit, OnDestroy {
  public allActivity$: Observable<any>|undefined;
  private destroy$ = new Subject();

  constructor(private store: Store<MarketState>, private navFacade:NavFacade, private mainFacade:MainFacade, private toastr:ToastService) {}

  ngOnInit(): void {
    this.setObservables();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  setObservables() {

    this.allActivity$ = this.mainFacade.allActivity$;
    this.mainFacade.allActivity();

  }

  formatDate(date){
    var myDatetimeFormat= 'llll';
    var myDatetimeString = moment(date).format(myDatetimeFormat);
    return myDatetimeString
  }

}
