import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastService } from 'angular-toastify';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MarketState } from 'src/app/lib/+state/market.reducer';
import { NavFacade } from 'src/app/nav/nav.facade';
import { MainFacade } from 'src/app/pages/main.facade';

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.scss']
})
export class ListingsComponent implements OnInit, OnDestroy {
  public user$: Observable<any>|undefined;
  public filters$: Observable<any>|undefined;
  public allSell$: Observable<any>|undefined;
  public selectUserBuy$: Observable<any>|undefined;
  private destroy$ = new Subject();
  filter:any='all';
  user:any;
  wallet:any;
  type:any='low';
  search:any='';
  nav:string='';
  constructor(private store: Store<MarketState>, private navFacade:NavFacade, private mainFacade:MainFacade, private toastr:ToastService,  private activeRoute:ActivatedRoute) {
    activeRoute.queryParams.subscribe(
      (params) => { 
        console.log(params)
        if(!params['filter']){}
        else if(this.filter !== params['filter']){
          this.filter = params['filter'];
          
        }
        else{
          this.filter = params['filter'];
        }
        
        
      });

  }

  ngOnInit(): void {
    this.setObservables();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  setObservables() {
    this.user$ = this.navFacade.user$;
    this.allSell$ = this.mainFacade.allSell$;
    this.selectUserBuy$ = this.mainFacade.selectUserBuy$;
    this.user$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 

      if(message){
        this.user = message;
        if(this.user.Wallets.length > 1){
          this.wallet = 'Mult-Wallet'
        }
        else{
          this.wallet = this.user.Wallets[0].slice(0, 4) + "..." + this.user.Wallets[0].slice(41, 44);
        }

        //this.burnWallet =  this.user.Wallets[0];
      }

    });


    this.mainFacade.allSell('low','');
  }

  formatDate(date){
    var myDatetimeFormat= 'llll';
    var myDatetimeString = moment(date).format(myDatetimeFormat);
    return myDatetimeString
  }

  selectListing(listing){
    //if(this.id )
    console.log(listing)
    if(listing?.CanResell == 0 ){
      this.toastr.info('This item cannot be resold.');
      return;
    }

    if(listing?.StopWalChange == 1){
      this.toastr.info('Wallet collection has completed. This item cannot be resold.');
      return;
    }

    if(listing?.AvailQuantity == 0 && !listing?.SellId){
      this.toastr.info('Quantity available to sell is 0.');
      return;
    }
    this.mainFacade.selectSell(listing);
  }

  selectBuy(listing){
    console.log(listing)
    this.mainFacade.selectUserBuy(listing);
  }

  runFilter(){
    this.mainFacade.allSell(this.type, this.search.length > 2 ? this.search : '');
  }

  runFilterSearch(){
    if(this.search.length < 3 && this.search.length < 0){
      return;   
    }
    this.mainFacade.allSell(this.type, this.search);
  }

}
