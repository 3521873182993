import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs'

//import { environment } from '../../../environments/environment.dev';
import {environment} from '../../../environments/environment.prod';
@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(private http: HttpClient ) {}

  get<T>(url: string, params: HttpParams = new HttpParams()): Observable<T> {
        return this.http.get<T>(`${environment.endpoint}${url}`, {
          headers: this.headers(),
          params,
        });
  }

  getCustom<T>(url: string, headersCust:any): Observable<T> {
    return this.http.get<T>(`${url}`, {
      headers: headersCust
    });
  }

  post<T, D>(url: string, data: D): Observable<T> {
        return this.http.post<T>(
          `${environment.endpoint}${url}`,
            JSON.stringify(data), { 
            headers: this.headers() 
        });
  }

  postCust<T, D>(url: string, headersCust:any, data: D): Observable<T> {
    let headerCustom;
    if(!headersCust){
      headerCustom = this.headers();
    }
    else {
      headerCustom = headersCust;
    }
    return this.http.post<T>(
      `${url}`,
        JSON.stringify(data), { 
        headers: headerCustom 
    });
  }

  patch<T, D>(url: string, data: D): Observable<T> {
    return this.http.patch<T>(
        `${environment.endpoint}${url}`,
          JSON.stringify(data), { 
          headers: this.headers() 
      });
  }

  put<T, D>(url: string, data: D): Observable<T> {
    return this.http.put<T>(
        `${environment.endpoint}${url}`,
          JSON.stringify(data), { 
          headers: this.headers() 
      });
  }

  delete<T>(url: string): Observable<T> {
    return this.http.get<T>(`${environment.endpoint}${url}`, {
        headers: this.headers(),
      });
  }

  headers(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
      'Authorization':environment.token,
      'Signature':localStorage.getItem('sig')
    };

    return new HttpHeaders(headersConfig);
  }

}
