import { Component, OnInit, TemplateRef, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import Moralis from 'moralis';
import { User } from '../lib/entities/User';
import { Store } from '@ngrx/store';
import { MarketState } from '../lib/+state/market.reducer';
import { Observable, Subject } from 'rxjs';
import { NavFacade } from './nav.facade';
import { takeUntil} from 'rxjs/operators';
import { threadId } from 'worker_threads';
import { AuthService } from '../lib/services/auth.service';
import { environment } from 'src/environments/environment.prod';
import { ActivatedRoute, Router } from '@angular/router';
import { MainFacade } from '../pages/main.facade';
import { ToastService } from 'angular-toastify';
var moment = require('moment-timezone');
//import { MarketComponent } from '../pages/market/market.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})

export class NavComponent implements OnInit, OnDestroy {
  public login$: Observable<any>|undefined;
  public error$: Observable<any>|undefined;
  public user$: Observable<any>|undefined;
  public nfts$: Observable<any>|undefined;
  public tutorial$: Observable<any>|undefined;
  public history$: Observable<any>|undefined;
  public burn$: Observable<any>|undefined;
  public trans$: Observable<any>|undefined;
  public pop$: Observable<any>|undefined;
  public mybids$: Observable<any>|undefined;
  public raffleWinners$: Observable<any>|undefined;
  public rafflesEntered$: Observable<any>|undefined;
  public listingLikes$: Observable<any>|undefined;
  public myLikes$: Observable<any>|undefined;
  public tokens$: Observable<any>|undefined;
  public perWallet$: Observable<any>|undefined;
  public project$: Observable<any>|undefined;
  public claim$: Observable<any>|undefined;
  public discordError$: Observable<any>|undefined;
  public projectLis$: Observable<any>|undefined;
  public nightMode$: Observable<any>|undefined;
  public notifications$: Observable<any>|undefined;
  public userList$: Observable<any>|undefined;
  public eth$: Observable<any>|undefined;
  public gas$: Observable<any>|undefined;
  public loading$: Observable<any>|undefined;
  public price$: Observable<any>|undefined;
  private destroy$ = new Subject();
  raffles:any = [];
  auct:any = [];
  showMobileMenu: boolean = false;
  //Modal Related
  loggedIn: boolean = false;
  loadingProfile:boolean = false;
  hasAccount: boolean = false;
  error:boolean = false;
  errorMessage:string = '';
  //Profile Data
  code:string='';
  wallet: any;
  walletFull: any;
  profileDropDown: boolean = false;
  user:User;
  showNfts:boolean = false;
  showProfile:boolean = false;
  showNftsAvatar:boolean = false;
  showPotsToWatch:boolean = false;
  showConvert:boolean = false;
  showDropdown:boolean = false;
  convert:number = 0;
  urlmanual:string='';
  selectList:any[] = [];
  avatarSelect:any = '';
  burnWallet:string='';
  perWallet:any[] = [];
  walletSplit:string ='';
  tokensOnWallet:number = 0;
  id:number;
  purchaseToChange:any;
  showWallet:boolean = false;
  selectedWallet:string='';
  night:boolean;
  jiraConvertConfirm:boolean = false;
  timeToClaim:number = 0;
  daysToClaim:number = 0;
  searchText:string='';
  showNotifications:boolean = false;
  notifList:any[] = [];
  WalletDrop:boolean=false;
  showJira:boolean=false;
  constructor(@Inject(DOCUMENT) private doc: Document, private store: Store<MarketState>, 
  private navFacade:NavFacade, private auth:AuthService, private mainfacade:MainFacade, private router:Router, private toast:ToastService) {
    document.addEventListener("mousedown", (ele):void => {     
      
      if(ele?.target && (ele.target as Element)?.className && (ele.target as Element)?.className?.indexOf('profile') < 0 ){
        this.profileDropDown = false;
      }

      if(ele?.target && (ele.target as Element).className && (ele.target as Element).className.indexOf('notif') < 0 && (ele.target as Element).className.indexOf('notif') < 0){
        this.showNotifications = false;
      }

      if(ele?.target && (ele.target as Element).className && (ele.target as Element).className.indexOf('mob') >= 0 && (ele.target as Element).className.indexOf('mob') >= 0){
        this.showMobileMenu = false;
      }

      if(ele?.target && (ele.target as Element).className && (ele.target as Element).className.indexOf('wal') < 0 && (ele.target as Element).className.indexOf('wal') < 0 && this.WalletDrop){
        this.WalletDrop = false;
        this.closePop();
      }
      
    });

  }

  ngOnInit(): void {
    this.pullDiscordAuth();
    this.code=sessionStorage.getItem('code');
    this.setObservables();
    this.claimLeft();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }


  async showPurchaseJira(){
    if(!localStorage.getItem('jiraPurchase')){
      this.showJira=true;
      localStorage.setItem('jiraPurchase', 'true');
      await this.startTimer(6);
      this.showJira=false;
    }
  }

  async startTimer(seconds:number){
    let mili = seconds * 1000;
    if(mili > 0){
      await this.delay(mili);
    }
    
  }

  delay(t) {
    return new Promise(resolve => setTimeout(resolve, t))
  }

  setObservables() {
    this.gas$ = this.navFacade.gas$;
    this.login$ = this.navFacade.login$;
    this.error$ = this.navFacade.error$;
    this.nfts$ = this.navFacade.nfts$
    this.user$ = this.navFacade.user$;
    this.tutorial$ = this.navFacade.tutorial$;
    this.history$ = this.navFacade.history$;
    this.burn$ = this.navFacade.burn$;
    this.trans$ = this.navFacade.trans$;
    this.pop$ = this.navFacade.pop$;
    this.mybids$ = this.navFacade.mybids$;
    this.rafflesEntered$ = this.navFacade.rafflesEntered$;
    this.raffleWinners$ = this.navFacade.raffleWinners$;
    this.listingLikes$ = this.navFacade.listingLikes$;
    this.myLikes$ = this.navFacade.myLikes$;
    this.tokens$ = this.navFacade.tokens$;
    this.perWallet$ = this.navFacade.perWallet$;
    this.project$ = this.navFacade.project$;
    this.claim$ = this.navFacade.claim$;
    this.discordError$ = this.navFacade.discordError$;
    this.projectLis$ = this.navFacade.projectLis$;
    this.nightMode$ = this.navFacade.nightMode$;
    this.notifications$ = this.navFacade.notifications$;
    this.userList$ = this.mainfacade.userList$;
    this.eth$ = this.navFacade.eth$;
    this.price$ = this.navFacade.price$;

    this.user$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 

      if(message){
        this.user = message;
        
        if(this.user.Wallets.length > 1){
          this.wallet = 'Mult-Wallet'
        }
        else{
          this.wallet = this.user.Wallets[0].slice(0, 4) + "..." + this.user.Wallets[0].slice(41, 44);
          this.burnWallet = ''
        }
        this.showPurchaseJira();
        //this.burnWallet =  this.user.Wallets[0];
      }

    });

    this.login$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.walletFull = message
        this.walletSplit = message.slice(0, 4) + "..." + message.slice(41, 44);
      }

    });

    this.nightMode$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.night = message
        
      }

    });
    
    this.claim$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        
      }

    });

    this.perWallet$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){

        this.perWallet = message
        
      }

    });

    this.project$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.id =message.Id;
        
      }

    });

    this.mybids$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.auct = message;
        
      }

    });

    this.navFacade.getProjects();
    this.login();

  }

  updateNightMode(){
    this.navFacade.nightMode(this.night);
  }

  showWalletModal(purchase){
    if(purchase.StopWalChange === 1){
      return;
    }
    this.purchaseToChange = purchase;
    this.showWallet = true;
  }

  submitWalletChange(){
    this.showWallet = false;
    this.navFacade.changeWallet(this.purchaseToChange.Id, this.purchaseToChange.UserId, this.selectedWallet);
    this.purchaseToChange = null;
    this.showWallet = false;

  }

  login(){
    this.navFacade.login(null);
  }

  setTokensForWallet(){
    if(this.perWallet.length === 0){
      return 0;
    }
    let tokens = this.perWallet.find((val)=>{
      return val.Address === this.burnWallet;
    });
    this.tokensOnWallet = tokens ? tokens.Amount : 0;
  }

  refreshPage(){

    sessionStorage.removeItem('code');
    sessionStorage.removeItem('state');
    window.location.href = environment.prod;
   
  }

  pullDiscordAuth(){
    let check = window.location.href.indexOf('code');

    if(check > -1){
      let base = window.location.href.split('code=')[1];
      let baseCode = base.split('&state=');
      var code = base.split('&state=')[0];
      var state = baseCode[1].split('#')[0];

      var stateValidated = this.auth.decryptToken(state);
      if(stateValidated){
        sessionStorage.setItem('code', code);
        sessionStorage.getItem('code');
   
      }
    }

  }

  AuthenticateDiscord(){
    this.pullDiscordAuth();
    this.code=sessionStorage.getItem('code');
    if(!this.code){
      this.auth.discordAuth();
    }
    else {
      sessionStorage.removeItem('code');
      this.navFacade.discordAuth(this.code, this.walletFull)
    }
  }

  showProfileDP() {
    this.profileDropDown = !this.profileDropDown;
  }

  logout() {
    this.navFacade.logout();
    this.profileDropDown = false;
  }

  clearModals(){
    this.showMobileMenu =false;
    this.showNfts = false;
    this.showProfile = false;
  
  }

  controlModal(loc:string){
    this.clearModals();
    if(loc === 'nfts'){
      this.showNfts = true;
    }
    else if(loc === 'profile'){
      this.showProfile = true;
      this.profileDropDown = false;
    }
    else if(loc === 'wallet'){

      this.navFacade.getPopup('wallet')
    }
    else if(loc === 'fav'){
      this.navFacade.getPopup('fav')
    }
    else if(loc === 'mypurchase'){
      this.navFacade.getPopup('mypurchase')
  
    }
    else if(loc === 'rafwin'){
      this.navFacade.getRaffleData(this.user.Id)
      this.navFacade.getPopup('rafwin');
    }
    else if(loc === 'bids'){
  
      this.navFacade.getPopup('bids')
    }
    else if(loc === 'collection'){
  
      this.navFacade.getPopup('collection')
    }
    else if(loc === 'walletDrop'){
      this.WalletDrop=true;
      this.navFacade.getPopup('walletDrop')
    }
    
  }

  controlRoute(loc){
    this.router.navigate(['profile']);
  }

  closePop(){
    this.navFacade.getPopup('');
  }

  selectAvatar(id:string, contract:string, nft:any){
    //if select then deselect
    this.avatarSelect = {
      token_id:id, 
      token_address:contract,
      url:nft.Image
    }
   
  }

  isAvatarSelected(id:string, contract:string){
    return this.avatarSelect.token_address === contract && this.avatarSelect.token_id === id;
  }

  confirmAvatar(){
    let url = '';
    if(this.avatarSelect){
      url=this.avatarSelect.url;
    }
    else{
      url=this.urlmanual;
    }
    this.showNftsAvatar = false;
    this.navFacade.updateAvatar(this.user.Id, url, this.user.Wallets[0]);
  }

  formatDate(date){
    var myDatetimeFormat= 'llll';
    var myDatetimeString = moment(date).format(myDatetimeFormat);
    return myDatetimeString
  }

  updateProject(projectName){
    this.showDropdown = false;
    this.navFacade.changeProject(projectName);
  }

  convertJira(){
    this.navFacade.getPopup('');
    this.jiraConvertConfirm = false;
    this.showConvert = false;
    this.navFacade.burn(this.convert, this.burnWallet);
    
    
  }


  like(listingId, type){
    this.navFacade.likesListing(this.user.Id, listingId,type);
  }

  navToList(ele, id, pid, gtype ){
    //this.mainfacade.selectListing(id);
    if(ele?.target && (ele.target as Element).className && (ele.target as Element).className.indexOf('like') >= 0){
      return; 
    }
    this.navFacade.getPopup('');
    this.router.navigate(['market'], { queryParams: {id: id, project:pid, gtype:gtype}});
  }

  navToMarket(project){
    this.mainfacade.selectListing(null)
    this.navFacade.getPopup('');
    this.navFacade.changeProject(project);
    this.router.navigate(['market']);
  }

  claimJira(){
    this.navFacade.claim(this.user.Id);
  }

  claimLeft(){
    const dayINeed = 7; // for sunday
    const today = moment().isoWeekday();
    
    if (today <= dayINeed) { 
      let dateClaim = moment().isoWeekday(dayINeed);
      let dateFor = dateClaim.format('YYYY')+"-"+dateClaim.format('MM')+"-"+(Number(dateClaim.format('DD'))+1)+" 04:00:00 AM UTC";
      let duration = moment.duration(moment(dateFor).diff(moment()));
   
      if(moment().isSameOrAfter(moment(dateFor))){
        return;
      }
      let seconds = duration.asSeconds();

      this.timeToClaim = !seconds || seconds > 0 ? seconds  : '0';
      this.daysToClaim = !seconds || seconds > 0 ? Math.floor(seconds/60/60/24)  : 0;
    }
  }


  searchRun(){
    if(this.searchText.length > 2){
      this.mainfacade.search(this.searchText);
    }
  }

  goToUser(id){
    console.log(id);
    this.searchText = '';
    this.router.navigate(['/userprofile'], { queryParams: {user: id}});
  }

  goToProfile(val){
    this.showMobileMenu =false;
    this.router.navigate(['/myprofile'], { queryParams: {list: val}});
  }

  


}
