import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import {MarketQuery} from '../lib/+state/market.selector';
import * as MarketActions from '../lib/+state/market.action';
import {MarketState} from '../lib/+state/market.reducer';

@Injectable({ providedIn: 'root' })
export class NavFacade {
    error$ = this.store.select(MarketQuery.getError);
    discordError$ = this.store.select(MarketQuery.getDiscordFail);
    user$ = this.store.select(MarketQuery.getUser);
    login$ = this.store.select(MarketQuery.login);
    nfts$ = this.store.select(MarketQuery.getNfts);
    isNav$ = this.store.select(MarketQuery.getNav);
    tutorial$ = this.store.select(MarketQuery.getTutorial);
    project$ = this.store.select(MarketQuery.getProject);
    history$ = this.store.select(MarketQuery.getHistory);
    burn$ = this.store.select(MarketQuery.getBurn);
    trans$ = this.store.select(MarketQuery.getTrans);
    pop$ = this.store.select(MarketQuery.getPop);
    mybids$ = this.store.select(MarketQuery.getMyBids);
    gen1$ = this.store.select(MarketQuery.getGen1);
    gen2$ = this.store.select(MarketQuery.getGen2);
    jiraverse$ = this.store.select(MarketQuery.getJiraverse);
    dark$ = this.store.select(MarketQuery.getDark);
    dwc$ = this.store.select(MarketQuery.getDWC);
    raffleWinners$ = this.store.select(MarketQuery.getRaffleWinners);
    rafflesEntered$ = this.store.select(MarketQuery.getRafflesEntered);
    listingLikes$ = this.store.select(MarketQuery.getListingLikes);
    myLikes$ = this.store.select(MarketQuery.getMyLikes);
    tokens$ = this.store.select(MarketQuery.getTokens);
    perWallet$ = this.store.select(MarketQuery.getPerWallet);
    claim$ = this.store.select(MarketQuery.getClaim);
    projectLis$ = this.store.select(MarketQuery.getProjectLis);
    nightMode$ = this.store.select(MarketQuery.getNight);
    notifications$ = this.store.select(MarketQuery.getNotifications);
    eth$ = this.store.select(MarketQuery.getEth);
    gas$ = this.store.select(MarketQuery.getGas);
    loading$ = this.store.select(MarketQuery.getLoading);
    price$ = this.store.select(MarketQuery.getPrice);
    constructor(private store: Store<MarketState>) {}
    
    login(provider:string){
        this.store.dispatch(MarketActions.login({provider:provider}));
    }

    logout(){
        this.store.dispatch(MarketActions.logout({data:null}));
    }

    getUser(address:string){
        this.store.dispatch(MarketActions.getUser({address:address}))
    }

    discordAuth(code:string, wallet:string){
        this.store.dispatch(MarketActions.discordData({code:code, wallet:wallet}))
    }

    updateAvatar(id:any, url:any, address:any){
        this.store.dispatch(MarketActions.updateAvatar({id:id, url:url, address:address}));
    }

    changeProject(project:any){
        this.store.dispatch(MarketActions.changeProject({project:project}));
    }

    getPopup(pop:any){
        this.store.dispatch(MarketActions.popup({pop:pop}));
    }

    getRaffleData(userId:any){
        this.store.dispatch(MarketActions.getRaffleWin({userId:userId}));
    }

    likesListing(userId:number, listingId:number, typeVal:string){
        this.store.dispatch(MarketActions.likeListing({userId:userId,listingId:listingId, typeVal:typeVal}));
    }
    
    burn(amount:number, wallet:string){
        this.store.dispatch(MarketActions.burnTokens({address:wallet,amount:amount}));
    }

    claim(userId:number){
        this.store.dispatch(MarketActions.claimJira({userIds:userId}))
    }

    getProjects(){
        this.store.dispatch(MarketActions.LoadProjects({data:null}))
    }

    changeWallet(pid:number, userid:number, wallet:string){
        this.store.dispatch(MarketActions.changeWallet({userid:userid,pid:pid,wallet:wallet}));
    }

    nightMode(night:boolean){
        this.store.dispatch(MarketActions.night({night:night}));
    }

   /* startTutorial(){
        this.store.dispatch(MarketActions.startTutorial({ data:null}));
    }*/

    estJiraPurchase(amount:number){
        this.store.dispatch(MarketActions.estJiraPurchase({amount:amount}));
    }

    JiraPurchase(amount:number){
        this.store.dispatch(MarketActions.setLoading({data:true}));
        this.store.dispatch(MarketActions.JiraPurchase({amount:amount}));
    }
    
}