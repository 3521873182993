import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavFacade } from 'src/app/nav/nav.facade';

@Component({
  selector: 'app-raffles',
  templateUrl: './raffles.component.html',
  styleUrls: ['./raffles.component.scss']
})
export class RafflesComponent implements OnInit, OnDestroy {
  public raffleWinners$: Observable<any>|undefined;
  public rafflesEntered$: Observable<any>|undefined;
  public nightMode$: Observable<any>|undefined;
  public user$: Observable<any>|undefined;
  private destroy$ = new Subject();
  user:any;
  constructor(private navFacade:NavFacade) { }

  ngOnInit(): void {
    this.setObservables();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }


  setObservables() {
    this.rafflesEntered$ = this.navFacade.rafflesEntered$;
    this.raffleWinners$ = this.navFacade.raffleWinners$;
    this.nightMode$ = this.navFacade.nightMode$;
    this.user$ = this.navFacade.user$;
    this.user$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 

      if(message){
        this.user = message;
        this.navFacade.getRaffleData(this.user.Id)
        
      }

    });
  }

}
