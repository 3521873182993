import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'angular-toastify';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/lib/services/api.service';
import { NavFacade } from 'src/app/nav/nav.facade';
import { MainFacade } from 'src/app/pages/main.facade';


@Component({
  selector: 'app-sell',
  templateUrl: './sell.component.html',
  styleUrls: ['./sell.component.scss']
})
export class SellComponent implements OnInit, OnDestroy {
  public error$: Observable<any>|undefined;
  public user$: Observable<any>|undefined;
  public selectSell$: Observable<any>|undefined;
  public selectUserBuy$: Observable<any>|undefined;
  public project$: Observable<any>|undefined;
  public nightMode$: Observable<any>|undefined;
  selectSell:any;
  selectBuy:any;
  baseListings:any[] = [];
  private destroy$ = new Subject();
  user:any;
  quantity:number = 1;
  sellPrice:number=0;
  routeId:number = 0;
  id:number = 1;
  scrolledLast:string = "";
  canceled: boolean = false;
  wallet:string='';
  sellConfirm='';

  constructor(private service: ApiService, private mainfacade:MainFacade,private sanitizer: DomSanitizer, private toastService: ToastService, 
    private router:Router, private activeRoute:ActivatedRoute, private navfacade:NavFacade) { 
    //const btn = document.querySelector("button.mobile-menu-button");
    //const menu = document.querySelector(".mobile-menu");
    //const menulink = document.querySelector(".menu-link");
    // Add Event Listeners

    activeRoute.queryParams.subscribe(
      (params) => { 
        this.routeId = params['id'];
        let temp = params['project'];
       
       
        
      });

  }

  ngOnInit() {
    //this.service.setToken();
    this.createSubscriptions();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  safeSVGRet(svgContent:any){
    return this.sanitizer.bypassSecurityTrustHtml(svgContent);
  }

  
  createSubscriptions(){
    this.navfacade.getPopup(null);
    this.user$ = this.mainfacade.user$;
    this.selectSell$ = this.mainfacade.selectSell$;
    this.selectUserBuy$ = this.mainfacade.selectUserBuy$;
    this.project$ = this.mainfacade.project$;
    this.nightMode$ = this.mainfacade.nightMode$;
    this.user$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){      
        this.user = message;
      }

    });

    this.selectSell$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        console.log(message)
        this.selectSell = message;
        this.sellPrice=message.Price;
        this.quantity = message.Quantity;


        
      }

    });

    this.selectUserBuy$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        console.log(message)
        this.selectBuy = message;
       

        
      }

    });

    this.project$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        if(message === 'all'){
          this.id = 4;
        }
        else if(message === 'godjira'){
          this.id = 1;
        }
        else if(message === 'dwc'){
          this.id = 2;
        }
        else if(message === 'darkechelon'){
          this.id = 3;
        }
      }

    });

    
  }


  listAssetForSale(){
    if(!this.sellPrice){
      this.toastService.error('Invalid sell price.');
      return;
    }
    if(!this.user){
      this.toastService.error('Please Login.');
      return;
    }
    this.sellConfirm = '';
    let listing = {
      SellId:this.selectSell?.SellId,
      PurchaseId:this.selectSell?.SellId ? this.selectSell?.PurchaseId :this.selectSell.Id,
      Price:this.sellPrice,
      Quantity:this.quantity,
      DurationInHours:0
    }

    this.mainfacade.listSell(this.user.Id, listing);
  }

  cancelSale(){
    
    if(!this.user){
      this.toastService.error('Please Login.');
      return;
    }

    if(!this.selectSell.SellId){
      this.toastService.error('Error Canceling, try refreshing the page.');
      return;
    }
    this.sellConfirm = '';

    this.mainfacade.cancelSell(this.user.Id, this.selectSell.SellId);
  }

  quantityChange(change){
    if(change === 'add'){
      if(this.selectSell.AvailQuantity >= this.quantity+1){
        this.quantity += 1;
      }
    }
    else{
      if(this.quantity > 1){
        this.quantity -= 1;
      }
    }
  }

  getPrice(price){
    return Number(price) * this.quantity;
  }

  closeListing(){
    this.mainfacade.selectSell(null);
  }

  closeBuy(){
    this.mainfacade.selectUserBuy(null);
  }

  purchaseUserListing(){
    if(!this.selectBuy){
      this.toastService.error('Please select a listing.');
      return;
    }

    if(!this.user){
      this.toastService.error('Please Login.');
      return;
    }

    if(!this.wallet){
      this.toastService.error('Please set a wallet.');
      return;
    }
    this.mainfacade.purchaseUserListing(this.user.Id, this.selectBuy.SellId, this.wallet)
  }

}
