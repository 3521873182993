import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mario',
  templateUrl: './mario.component.html',
  styleUrls: ['./mario.component.scss']
})
export class MarioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
