import { domain, clientId } from '../auth_config.json';
export const environment = {
  production: true,
  urlType:"PROD",
  moralisAppId:"",
  moralis:'',
  moralisUrl:"",
  //endpoint:"http://localhost:5000",
  //base:"http://localhost:8546/",
  endpoint:"https://godjiramarket.xyz",
  //prod:"http://localhost:8546/#/home",
  base:"https://www.jira.exchange/#/home",
  prod:"https://www.jira.exchange/#/home",
  token:"jdjaosiij28892SIAHI049923701CIGknlklajal",
  discordauth:"https://discord.com/api/oauth2/authorize",
  discordClient:"1006561993681027123",
  siteKey:"6LeWI7IeAAAAAFKUA8VrkIOuhV-8Qc0A2DhlymRf",
  jira:"0x517ab044bda9629e785657dbbcae95c40c8f452c",
  burn:"0x2815892d7F49A51CA86B6a805c4FBBF5dC55D8Db",
  purjira:"0x66D9872a6B7b440daE7C40Bab0073212b3CAc841",
  stjira:"0xe71a8ddcad4a950a1bbea58a301f62b5337ecb5c",
};
