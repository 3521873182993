import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { ApiService } from './api.service';
import { createClient } from 'urql'
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})

export class MainService {
  constructor(private apiService: ApiService) {}

  getListings(id){
    return this.apiService.post('/api/listings',{companyId:id})
  }

  timedListings(listingIds){
    return this.apiService.post('/api/listings/refresh',{listings:listingIds})
  }

  getEndedListings(id){
    return this.apiService.post('/api/listings/Ended',{companyId:id})
  }

  getHotListings(id){
    return this.apiService.post('/api/listings/HotListings',{companyId:id})
  }

  getTopSpenders(){
    return this.apiService.post('/api/listings/TopSpenders',{})
  }

  getUser(wallet: string){
    return this.apiService.post('/auth/user',{wallet:wallet})
  }

  updateAvatar(userId:string, url:string){
    return this.apiService.post('/Auth/avatar',{id:userId, url:url});
  }

  likeListing(userId:string, listingId:string, type:string){
    return this.apiService.post('/Auth/fav',{userId:userId, listingId:listingId, type:type});
  }

  changeWallet(pid:number, userId:number,wallet: string){
    return this.apiService.post('/api/purchase/change',{pid:pid, userId:userId, wallet:wallet});
  }

  deleteWallet(userId:number,wallet: string){
    return this.apiService.post('/auth/deletewallet',{userId:userId, wallet:wallet});
  }

  makePurchase(userId:number, listingId:number, quantity:number,wallet: string, discordId:string){
    return this.apiService.post('/api/purchase',{userId:userId, listingId:listingId, quantity:quantity,wallet:wallet, discordId:discordId});
  }

  placeBid(userId:number, listingId:number, amount:number, wallet:string ){
    return this.apiService.post('/api/purchase/bid',{userId:userId, listingId:listingId, bid:amount, wallet:wallet});
  }

  getListingUpdate(listingId:number ){
    return this.apiService.post('/api/listings/update',{listingId:listingId});
  }

  getRaffleWinners(userId:number ){
    return this.apiService.post('/api/listings/raffles',{userId:userId});
  }

  claim(userId:number ){
    return this.apiService.post('/Auth/claim',{userId:userId});
  }
  
  getProjects( ){
    return this.apiService.post('/api/listings/projects',{});
  }

  getRecentBuy(projectId:number){
    return this.apiService.post('/api/listings/Recent',{projId:projectId})
  }

  getMySell(userId:number){
    return this.apiService.post('/api/sell/my',{userId:userId})
  }

  getAvailableSell(type, search){
    return this.apiService.post('/api/sell',{type:type, search:search})
  }

  listSell(listing, userId){
    return this.apiService.post('/api/sell/list',{listing:listing, userId:userId});
  }

  cancelSell(listingId, userId){
    return this.apiService.post('/api/sell/cancel',{listingId:listingId, userId:userId})
  }

  getMyActivity(userId:number){
    return this.apiService.post('/api/sell/myactivity',{userId:userId})
  }

  getAllActivity(){
    return this.apiService.post('/api/sell/activity',{})
  }

  purchaseUserListing(userId:number, listingId:number,wallet: string){
    return this.apiService.post('/api/purchase/listing',{userId:userId, listingId:listingId, wallet:wallet});
  }

  userSearch(search: string){
    return this.apiService.post('/Auth/Search',{search:search});
  }

  getUserProfile(userId){
    return this.apiService.post('/Auth/userprofile',{userId:userId});
  }

  getNFTs(wallet){
    return this.apiService.post('/Auth/NFTS',{wallet:wallet});
  }

  getETH(wallet){
    return this.apiService.post('/Auth/ETH',{wallet:wallet});
  }

}