import { Component, OnInit, TemplateRef, HostListener, Inject, OnDestroy } from '@angular/core';
import { ApiService } from 'src/app/lib/services/api.service';
import { Observable, Subject } from 'rxjs';
import { MainFacade } from '../main.facade';
import { takeUntil} from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from 'angular-toastify';
import { ActivatedRoute, Router } from '@angular/router';
import { NavFacade } from 'src/app/nav/nav.facade';

var moment = require('moment-timezone');

declare var myExtObject: any;
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  public error$: Observable<any>|undefined;
  public user$: Observable<any>|undefined;
  public nfts$: Observable<any>|undefined;
  public listings$: Observable<any>|undefined;
  public tutorial$: Observable<any>|undefined;
  public filterListings$: Observable<any>|undefined;
  public selectListing$: Observable<any>|undefined;
  public myLikes$: Observable<any>|undefined;
  public project$: Observable<any>|undefined;
  public projectLis$: Observable<any>|undefined;
  public filters$: Observable<any>|undefined;
  public nightMode$: Observable<any>|undefined;
  public purchase$: Observable<any>|undefined;
  public bid$: Observable<any>|undefined;
  projectLis:any[];
  myLikes:any[];
  selectListing:any;
  baseListings:any[] = [];
  filteredListings:any[] = [];
  private destroy$ = new Subject();
  user:any;
  quantity:number = 1;
  bid:number=0;
  public typeFilter:string = 'All';
  public jiraTypeFilter:string = 'Gen1';
  showFilter:boolean = false;
  minRange:number;
  maxRange:number;
  active:boolean = true;
  listingNameSearch:any;
  routeId:number = 0;
  pId:number = 0;
  id:number = 1;
  halfHrRefreshLoop:boolean = false;
  fifteenRefreshLoop:boolean = false;
  sortFilter:string;
  scrolledLast:string = "";
  project:any;
  showFil:boolean = false;

  constructor(private service: ApiService, private mainfacade:MainFacade,private sanitizer: DomSanitizer, private toastService: ToastService, 
    private router:Router, private activeRoute:ActivatedRoute, private navfacade:NavFacade) { 
    
      activeRoute.queryParams.subscribe(
        (params) => { 

          if(this.routeId !== params['id']){
            this.routeId = params['id'];
            this.pId = params['project'];
            if(params['gtype']){
              this.jiraTypeFilter = params['gtype'];
            }
            
            this.navfacade.getProjects();
          }
          else{
            this.routeId = params['id'];
            this.pId = params['project'];
            if(params['gtype']){
              this.jiraTypeFilter = params['gtype'];
            }
          }
          
          
        });

        document.addEventListener("mousedown", (ele):void => {
          
          if(ele?.target && (ele.target as Element).className && (ele.target as Element).className.indexOf('filter') < 0 && (ele.target as Element).className.indexOf('filter') < 0){
         
              //this.mainfacade.showFilters(false);
         
           
          }
          
          
        });

  }

  ngOnInit() {
    //this.service.setToken();
    this.createSubscriptions();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  safeSVGRet(svgContent:any){
    return this.sanitizer.bypassSecurityTrustHtml(svgContent);
  }


  selectFromRoute(){

  }

  setType(name:string){
    if(name === 'All'){
      this.typeFilter = 'All';
    }
    else if(name === 'Auction'){
      this.typeFilter = 'Auction';
    }
    else if(name === 'BuyNow'){
      this.typeFilter = 'BuyNow';
    }
    else if(name === 'Raffles'){
      this.typeFilter = 'Raffles';
    }
  }

  createSubscriptions(){
    this.listings$ = this.mainfacade.listings$;
    this.user$ = this.mainfacade.user$;
    this.selectListing$ = this.mainfacade.selectListing$;
    this.myLikes$ = this.mainfacade.myLikes$;
    this.project$ = this.mainfacade.project$;
    this.projectLis$ = this.mainfacade.projectLis$;
    this.filters$ = this.mainfacade.filters$;
    this.nightMode$ = this.navfacade.nightMode$;
    this.purchase$ = this.mainfacade.purchase$;
    this.bid$ = this.mainfacade.bid$;
    this.user$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
      
        this.user = message;
        //this.user.Wallet = this.user.Wallets[0];
        //this.userId = message.Id;
        //this.mainfacade.potByUser(this.userId);
      }

    });

    
    this.selectListing$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        
        let seconds = 30;
        if(message.Type === 'Auction' && message.AuctionTypeId === 1){
          seconds = 5
        }

        //this.selectListingLoop(message.Id, seconds);
      }

    });

    this.listings$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{    
      if(message){
 
        this.listingLogic(message);

      }

    });

    this.bid$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.mainfacade.selectListing(null);
        this.mainfacade.getListings(this.id);
      }

    });

    
    this.myLikes$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.myLikes = message;
        //this.userId = message.Id;
        //this.mainfacade.potByUser(this.userId);
      }

    });

    this.project$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
     
      if(message){
        this.id = message.Id;
        this.mainfacade.getRecentBuy(this.id);
        this.mainfacade.getListings(this.id);
        
      }

    });

    this.purchase$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.mainfacade.selectListing(null);
        this.mainfacade.getListings(this.id);
      }

    });

    this.projectLis$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){

        this.projectLis = message;
        if(this.pId !== 0){

          let temp = this.projectLis.find((val)=>{
            return Number(val.Id) === Number(this.pId);
          });
         
          if(temp){
            this.navfacade.changeProject(temp);
            this.pId = 0;
          }
          
        }
      }

    });



    //this.mainfacade.getListings(this.id);
    
  }

  listingLogic(message){
    if(this.routeId !== 0){
      
      let foundSelect = message.find((listing)=>{
       
        return Number(listing.Id) === Number(this.routeId);
      });
      
      if(foundSelect){
        this.select(null,foundSelect);
        this.routeId = 0;
      }
      
    }
    //Loop through each listing
    //If listing ends with in 10 mins
    let endingInhalfhrs = [];
    let endingIn15Sec =[];
    message.forEach((ls)=>{

      if(ls.Type === 'Auction' && ls.TypeName === 'Standard' && ls.Active === 1){
        if(this.getTime(ls.AuctionEnd) < 15 && this.getTime(ls.AuctionEnd)>0){
          endingIn15Sec.push(ls.Id);
        }
        else{
          endingInhalfhrs.push(ls.Id);
        }
      }
      else if(ls.Type === 'Auction' && ls.Active === 1){
        if(this.getTime(ls.AuctionEnd) < 5 && this.getTime(ls.AuctionEnd)>0){
          endingIn15Sec.push(ls.Id);
        }
        else if(this.getTime(ls.AuctionEnd) < 1800 && this.getTime(ls.AuctionEnd)>0) {
          endingInhalfhrs.push(ls.Id);
        }
      }
      else if(ls.Type === 'Raffle' && ls.Active === 1){
        if(this.getTime(ls.RaffleEndDate) < 5 && this.getTime(ls.RaffleEndDate)>0){
          endingIn15Sec.push(ls.Id);
        }
        else if(this.getTime(ls.RaffleEndDate) < 1800 && this.getTime(ls.RaffleEndDate)>0){
              endingInhalfhrs.push(ls.Id);
        }
      }

    });
    if(endingIn15Sec.length > 0 && !this.fifteenRefreshLoop){
      this.fifteenSec(message, endingIn15Sec, this.id);
    }
    if(endingInhalfhrs.length > 0 && !this.halfHrRefreshLoop){
      this.halfHour(message, endingInhalfhrs, this.id);
    }

    this.baseListings = message;
    this.filterListings(null,null);
    //this.filteredListings = this.baseListings;
  }

  async halfHour(showed,listings, project){
    this.halfHrRefreshLoop = true;
    await this.delay(180*1000);
    this.halfHrRefreshLoop = false;
    if(this.id === project){
      this.mainfacade.timedListing(showed,listings);
    }
    else{
      this.mainfacade.getListings(this.id)
    }
  }

  async fifteenSec(showed,listings, project){
    this.fifteenRefreshLoop = true;
    await this.delay(2*1000);
    this.fifteenRefreshLoop = false;
    if(this.id === project){
      this.mainfacade.timedListing(showed,listings);
    }
    else{
      this.mainfacade.getListings(this.id)
    }

  }

  async selectListingLoop(listingId, seconds){
    await this.startTimer(seconds);
    if(this.selectListing && this.selectListing.Id === listingId){
      this.mainfacade.selectListing(listingId);
    }
    
  }

  async startTimer(seconds:number){
    let mili = seconds * 1000;
    if(mili > 0){
      await this.delay(mili);
    }
    
  }

  delay(t) {
    return new Promise(resolve => setTimeout(resolve, t))
  }
  
  getTime(date:any){
    if(!date){
      return '0';
    }
    var d = new Date();
    var dP = new Date(date);
    var myDatetimeFormat= "YYYY-MM-DD hh:mm:ss A";
    var myDatetimeString = moment.utc(d).format(myDatetimeFormat);/// tz(myTimezone).format(myDatetimeFormat);
    var myDatetimeStringPost = moment.utc(dP).format(myDatetimeFormat);
    let duration = moment.duration(moment(myDatetimeStringPost).diff(moment(myDatetimeString)));

    if(moment(myDatetimeString).isSameOrAfter(moment(myDatetimeStringPost))){
      return 0;
    }
    let seconds = duration.asSeconds();
    
    return !seconds || seconds > 0 ? seconds  : '0';
  }

  getDays(date:any){
    if(!date){
      return '0';
    }
    var d = new Date();
    var dP = new Date(date);
    var myDatetimeFormat= "YYYY-MM-DD hh:mm:ss A";
    var myDatetimeString = moment.utc(d).format(myDatetimeFormat);/// tz(myTimezone).format(myDatetimeFormat);
    var myDatetimeStringPost = moment.utc(dP).format(myDatetimeFormat);
    let duration = moment.duration(moment(myDatetimeStringPost).diff(moment(myDatetimeString)));
   
    if(moment(myDatetimeString).isSameOrAfter(moment(myDatetimeStringPost))){
      return 0;
    }
    let seconds = duration.asSeconds();
 
    return !seconds || seconds > 0 ? Math.floor(seconds/60/60/24)  : 0;
  }

  select(ele,listing){
    //if(this.id )
    if(ele?.target && (ele.target as Element).className && (ele.target as Element).className.indexOf('like') >= 0){
      return;
    }
    if(listing.Type==="Auction"){
      this.bid = listing.CurrentPrice;
    }
    else{
      this.quantity = 1;
    }
    this.selectListing = listing;
    this.mainfacade.selectListing(listing);
  }

  clear(){
    if(this.selectListing){
      this.selectListing = null;
    }
    
  }

  like(listingId, type){
    this.mainfacade.likesListing(this.user.Id, listingId,type);
  }

  liked(listingId){
    return this.myLikes.find((x)=>{
      return listingId === x.Id;
    })
  }

  toggleActive(){
    this.clearFilters();
    if(this.active){
      this.mainfacade.getListings(this.id);
    }
    else{
      this.mainfacade.getEndedListings(this.id);
    }
    
  }

  filterListings(type,jtype){   
    if(type){
      this.typeFilter = type;

    }
    if(jtype){
      this.jiraTypeFilter = jtype;
    }
   
    //Type
    let temp=this.baseListings.filter((listing)=>{
      let typef = false;
      let typeGf = false;
      let maxf = false;
      let minf = false;
      let namef = false;
      let activef = false;
      
      if(this.typeFilter){
        if(this.typeFilter === 'All'){
          typef= true;
        }
        else if(listing.Type === this.typeFilter){
          typef = true;
        }
        else{
          typef = false;
        }
        
        
      }
      else{
        typef = true;
      }

      if(this.jiraTypeFilter && this.id === 1){
        if(listing.GodjiraType === this.jiraTypeFilter){
          typeGf = true;
        }
        else{
          typeGf = false;
        }
        
        
      }
      else{
        typeGf = true;
      }

      if(this.minRange){
        if(((listing.Type === 'Auction') && listing.CurrentPrice >= this.minRange)
        || ((listing.Type !== 'Auction') && listing.Price >= this.minRange)){
          minf = true;
        }
        else{
          minf = false;
        }
        
      }
      else{
        minf = true;
      }

      if(this.maxRange){

        if((listing.Type === 'Auction'  && listing.CurrentPrice <= this.maxRange)
        || ((listing.Type !== 'Auction') && listing.Price <= this.maxRange)){
       
          maxf = true;
        }
        else{
          maxf = false;
        }
        
      }
      else{
        maxf = true;
      }

      if(this.listingNameSearch){

        if(listing.ListingName.toLowerCase().indexOf(this.listingNameSearch.toLowerCase()) >=0){
          namef=true;
        }
        else{
          namef=false;
        }
      }
      else{
        namef=true;
      }
      
      if(this.active){
        if((listing.Active || listing.Preview)){
          activef = true;
        }
        else{
          activef = false;
        }
      }
      else{
        
        activef=true;
      }
      
      return typef && maxf && minf && namef && activef && typeGf;


    });

    this.filteredListings=temp;


    //sort

  }

  clearFilters(){
    //this.active = false;
    this.sortFilter = '';
    this.typeFilter = 'All';
    this.maxRange=null;
    this.minRange=null;
    this.listingNameSearch = null;
    
    this.filteredListings = this.baseListings;
  }

  sort(type){
    if(type){
      //this.sortFilter = type;
    }
    
    if(this.sortFilter === 'recent'){
      let sorted = this.filteredListings.sort((a,b)=>{
        if(a.Id < b.Id){
          return 1
        }
        else{
          return -1
        }
    })
    }
    else if(this.sortFilter === 'lowest'){
      let sorted = this.filteredListings.sort((a,b)=>{
        let prA = a.Type === 'Auction' ? a.CurrentPrice : a.Price;
        let prB = b.Type === 'Auction' ? b.CurrentPrice : b.Price;

        if(prA < prB ){
          return -1
        }
        else{
          return 1
        }
    })
    }
    else if(this.sortFilter === 'highest'){
      let sorted = this.filteredListings.sort((a,b)=>{
        let prA = a.Type === 'Auction' ? a.CurrentPrice : a.Price;
        let prB = b.Type === 'Auction' ? b.CurrentPrice : b.Price;

        if(prA < prB ){
          return 1
        }
        else{
          return -1
        }
    })
    }
    else if(this.sortFilter === 'end'){
      let sorted = this.filteredListings.sort((a,b)=>{
        let prA = a.Type === 'Auction' ? a.AuctionEnd : a.RaffleEndDate;
        let prB = b.Type === 'Auction' ? b.AuctionEnd : b.RaffleEndDate;
        var d = new Date(prA);
        var dP = new Date(prB);
        var myDatetimeFormat= "YYYY-MM-DD hh:mm:ss A";
        var myDatetimeString = moment.utc(d).format(myDatetimeFormat);/// tz(myTimezone).format(myDatetimeFormat);
        var myDatetimeStringPost = moment.utc(dP).format(myDatetimeFormat);
        
        if(moment(myDatetimeString).isSameOrAfter(moment(myDatetimeStringPost))){
          return 1;
        }
        else{
          return -1
        }
    })
    }
    
  }

  toggleFilter(){
    this.showFil = !this.showFil;
    this.mainfacade.showFilters(this.showFil);
  }

  closFilter(){
    this.mainfacade.showFilters(false);
  }

  
}
