import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/lib/entities/User';
import { NavFacade } from 'src/app/nav/nav.facade';
import { MainFacade } from 'src/app/pages/main.facade';
var moment = require('moment-timezone');
@Component({
  selector: 'app-wallets',
  templateUrl: './wallets.component.html',
  styleUrls: ['./wallets.component.scss']
})
export class WalletsComponent implements OnInit, OnDestroy {

  public login$: Observable<any>|undefined;
  public error$: Observable<any>|undefined;
  public user$: Observable<any>|undefined;
  
  public tokens$: Observable<any>|undefined;
  public perWallet$: Observable<any>|undefined;
  public project$: Observable<any>|undefined;
  public claim$: Observable<any>|undefined;
  
  public nightMode$: Observable<any>|undefined;
  
  private destroy$ = new Subject();
  
  wallet: any;
  walletFull: any;
  profileDropDown: boolean = false;
  user:User;
  
  urlmanual:string='';
  
  burnWallet:string='';
  perWallet:any[] = [];
  walletSplit:string ='';
  tokensOnWallet:number = 0;

  showWallet:boolean = false;
  selectedWallet:string='';
  night:boolean;
  
  timeToClaim:number = 0;
  daysToClaim:number = 0;
  
  showJira:boolean=false;
  constructor(
  private navFacade:NavFacade, private mainfacade:MainFacade, private router:Router, private toast:ToastService) {
    

  }

  ngOnInit(): void {
    
    this.setObservables();
    this.claimLeft();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }



  setObservables() {

    this.login$ = this.navFacade.login$;
    this.error$ = this.navFacade.error$;
    this.user$ = this.navFacade.user$;
    this.tokens$ = this.navFacade.tokens$;
    this.perWallet$ = this.navFacade.perWallet$;
    this.project$ = this.navFacade.project$;
    this.claim$ = this.navFacade.claim$;
    this.nightMode$ = this.navFacade.nightMode$;

    this.user$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 

      if(message){
        this.user = message;
        
        if(this.user.Wallets.length > 1){
          this.wallet = 'Mult-Wallet'
        }
        else{
          this.wallet = this.user.Wallets[0].slice(0, 4) + "..." + this.user.Wallets[0].slice(41, 44);
          this.burnWallet = ''
        }
        
      }

    });

    this.login$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.walletFull = message
        this.walletSplit = message.slice(0, 4) + "..." + message.slice(41, 44);
      }

    });

    this.nightMode$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.night = message
        
      }

    });
    
    this.claim$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        
      }

    });

    this.perWallet$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){

        this.perWallet = message
        
      }

    });


  }

  

  setTokensForWallet(){
    if(this.perWallet.length === 0){
      return 0;
    }
    let tokens = this.perWallet.find((val)=>{
      return val.Address === this.burnWallet;
    });
    this.tokensOnWallet = tokens ? tokens.Amount : 0;
  }


  controlModal(loc:string){
    this.navFacade.getPopup('walletDrop')
    
    
  }

  controlRoute(loc){
    this.router.navigate(['profile']);
  }

  closePop(){
    this.navFacade.getPopup('');
  }

  formatDate(date){
    var myDatetimeFormat= 'llll';
    var myDatetimeString = moment(date).format(myDatetimeFormat);
    return myDatetimeString
  }



  claimJira(){
    this.navFacade.claim(this.user.Id);
  }

  claimLeft(){
    const dayINeed = 7; // for sunday
    const today = moment().isoWeekday();
    
    if (today <= dayINeed) { 
      let dateClaim = moment().isoWeekday(dayINeed);
      let dateFor = dateClaim.format('YYYY')+"-"+dateClaim.format('MM')+"-"+(Number(dateClaim.format('DD'))+1)+" 04:00:00 AM UTC";
      let duration = moment.duration(moment(dateFor).diff(moment()));
   
      if(moment().isSameOrAfter(moment(dateFor))){
        return;
      }
      let seconds = duration.asSeconds();

      this.timeToClaim = !seconds || seconds > 0 ? seconds  : '0';
      this.daysToClaim = !seconds || seconds > 0 ? Math.floor(seconds/60/60/24)  : 0;
    }
  }


  


}
